import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export class GeoPosition
{
    position: any;
    error: {
        code: number;
        message: string;
    }
}

@Injectable()
export class GeoLocationService
{
    private _location = new BehaviorSubject<GeoPosition>(null);

    constructor()
    {
        if (navigator.geolocation)
        {
            navigator.geolocation.watchPosition(
                (position: any) => {
                    this._location.next({
                        position: position,
                        error: null
                    });
                },
                (positionError: any) => {
                    this._location.next({
                        position: null,
                        error: {
                            code: positionError.code,
                            message: positionError.message
                        }
                    });
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 0
                }
            );
        }
    }

    public get currentPosition$(): Observable<GeoPosition>
    {
        return this._location.asObservable();
    }

    public getCurrentPosition(): GeoPosition
    {
        return this._location.getValue();
    }
}
