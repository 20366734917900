<mat-dialog-content>
    <div mat-dialog-title>{{data.title}}</div>
    <p [innerHTML]="data.message"></p>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" mat-button class="btn is-primary" (click)="onConfirm()">
        {{this.data.confirm ? this.data.confirm : 'Yes'}}
    </button>

    <button type="button" mat-stroked-button class="btn" (click)="onDismiss()">
        {{this.data.dismiss ? this.data.dismiss : 'No'}}
    </button>
</mat-dialog-actions>
