import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PwaService
{
    private promptEvent: any;

    constructor()
    {
        window.addEventListener('beforeinstallprompt', event =>
        {
            this.promptEvent = event;
            this.promptEvent.prompt();
        });
    }

}
