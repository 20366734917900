import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from "@angular/forms";
import { AddIdentityJuniorComponent } from "./add-identity-junior.component";
import { AddIdentityComponent } from "./add-identity.component";
import { AddIdentityDialog } from "./add-identity.dialog";
import { PipesModule } from "@ddfshared/pipes/pipes.module";
import { AddNewIdentityComponent } from './add-new-identity.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        PipesModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule
    ],
    declarations: [
        AddIdentityJuniorComponent,
        AddIdentityComponent,
        AddIdentityDialog,
        AddNewIdentityComponent
    ],
    exports: [
        AddIdentityJuniorComponent,
        AddIdentityComponent,
        AddIdentityDialog,
        AddNewIdentityComponent
    ]
})
export class AddIdentityModule { }
