import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '../providers';
import { IQueryResponse, QueryService } from '../providers/query.service';
import { PayoutDistribution } from '@ddfshared/models/distribution-payout';


@Injectable({providedIn: 'any'})
export class DistributionPayoutRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService){}

    public async initiateDistributionPayout(data: any): Promise<any>
    {
        return this.commandService.publishAsync('DistributionPayoutInitiate', data);
    }

    public async getDistributionPayouts(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('DistributionPayoutsQuery', data);
        return response.result;
    }

    public async getPayoutHistory(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('DistributionPayoutHistoryQuery', data);
        return response.result;
    }

    public async getPayoutDistributions(search: string = null): Promise<any> {
        const response = await this.queryService.queryAsync("PayoutDistributionsQuery", { SearchString: search });
        return response.result;
    }
}

