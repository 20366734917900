<form [formGroup]="form" novalidate (submit)="submitAsync($event)">

    <dl *ngIf="!isRenewable">

        <dt>Policyholder</dt>
        <dd>{{parent.policyholder.name}}</dd>

        <dt>Product</dt>
        <dd>{{parent.product.productName}} ({{parent.product.productPrice | defaultCurrency:parent.product.productCurrency}})</dd>


        <ng-container *ngIf="parent.dependants.length > 0">

            <table class="table">
                <thead>
                    <tr>
                        <td>{{ 'name' | translate }}</td>
                        <td>Identification</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dependant of parent.dependants">
                        <td>{{dependant.name}}</td>
                        <td>{{dependant.idNumber}}</td>
                    </tr>
                </tbody>
            </table>

        </ng-container>

    </dl>


    <div class="pv-15">
        <mat-checkbox formControlName="confirmed" [checked]="true">I confirm that the above information is correct</mat-checkbox>
    </div>

    <div class="layout-row layout-align-space-between-start">

        <button type="button" mat-button class="btn" (click)="previous()">
            Previous
        </button>

        <button type="submit" mat-button class="btn" [ngClass]="{'is-primary': form.valid}"
            [disabled]="form.invalid || isSubmitted">
            Confirm
        </button>

    </div>

</form>
