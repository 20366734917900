import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@ddfshared/abstracts/base.component';
import { CommandService } from '@ddfshared/providers/command.service';

export enum GenderModel
{
    Undefined = 0,
    Male = 1,
    Female = 2
}

export interface UserModel
{
    id: string;
    createdAt: string;
    firstName: string;
    familyName: string;
    name: string;
    email?: string;
    nationalId: string;
    phoneNumber: string;
    dateOfBirth: string;
    gender: GenderModel;
    genderText: string;
}

@Component({
    templateUrl: 'change-password.html'
})
export class ChangePasswordComponent extends BaseComponent
{
    public form: UntypedFormGroup;
    public loading = false;
    public message: string | null = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { user: UserModel },
        private dialogRef: MatDialogRef<ChangePasswordComponent>,
        private commandService: CommandService,
        private snackbar: MatSnackBar
    )
    {
        super();

        this.form = new UntypedFormGroup({
            nationalId: new UntypedFormControl(data.user.nationalId, [Validators.required]),
            phone: new UntypedFormControl(data.user.phoneNumber, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
            confirm: new UntypedFormControl(null, [Validators.required ], [this.confirmPasswordAsync.bind(this)]),
        });
    }

    private async confirmPasswordAsync(ctrlConfirm: AbstractControl): Promise<{ invalid: boolean } | null>
    {
        const ctrlPasswd = this.form.get('password');
        if (!ctrlPasswd) return {invalid: true};

        if (ctrlPasswd.value !== ctrlConfirm.value)
        {
            return {invalid: true};
        }

        return null;
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();

        this.message = null;

        if (this.form.invalid)
        {
            this.message = 'Fill in required fields';
            return;
        }

        const data = this.form.value;
        const response = await this.commandService.publishAsync('UserUpdatePassword', data);

        this.snackbar.open(response && response.isSuccess
            ? 'Success! You will receive your password by SMS shortly'
            : response.message);

        this.dialogRef.close(response.isSuccess);
    }
}
