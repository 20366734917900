<h2 mat-dialog-title class="text-uppercase">{{data.title}}</h2>

<form [formGroup]="form" novalidate autocomplete="off" (submit)="submit($event)">

    <mat-dialog-content>

        <div class="layout-column">

            <mat-form-field appearance="outline" class="flex">
                <mat-label>Select Filter</mat-label>
                <mat-select formControlName="filter">
                    <mat-option *ngFor="let filter of filters" [value]="filter.value">{{filter.name}}</mat-option>
                </mat-select>
            </mat-form-field>
                
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button type="submit" mat-button class="btn is-primary">
            Filter
        </button>

        <button type="button" mat-button class="btn" mat-dialog-close>
            Cancel
        </button>

    </mat-dialog-actions>

</form>
