import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { EmptyListModule } from "../empty-list/empty-list.module";
import { SkeletonModule } from "../skeleton/skeleton.module";
import { SelectProductComponent } from "./components/select-product";
import { SelectProductDialogComponent } from "./components/select-product.dialog";

@NgModule({
    imports: [
        CommonModule,
        EmptyListModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        HttpClientModule,
        ReactiveFormsModule,
        SkeletonModule,
    ],
    providers: [],
    declarations: [
        SelectProductComponent,
        SelectProductDialogComponent
    ],
    exports: [
        SelectProductComponent,
        SelectProductDialogComponent
    ]
})
export class SelectProductModule { }
