import { NgModule } from "@angular/core";
import { ActiveInactivePipe } from "./active-inactive.pipe";
import { DefaultDatePipe } from "./default-date.pipe";
import { InitialsPipe } from "./initials.pipe";
import { SearchPipe } from "./search.pipe";
import { DefaultCurrencyPipe } from "./default-currency.pipe";

@NgModule({
    imports: [],
    declarations: [
        ActiveInactivePipe,
        DefaultDatePipe,
        DefaultCurrencyPipe,
        InitialsPipe,
        SearchPipe,
    ],
    exports: [
        ActiveInactivePipe,
        DefaultDatePipe,
        DefaultCurrencyPipe,
        InitialsPipe,
        SearchPipe,
    ]
})
export class PipesModule { }