import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ddfshared/abstracts/base.component';

@Component({
    selector: 'skeleton',
    templateUrl: './skeleton.component.html'
})
export class SkeletonComponent extends BaseComponent
{

    @Input() 
    public items: number = 10;
    
    constructor()
    {
        super();
    }
}