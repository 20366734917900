<h2 mat-dialog-title class="text-uppercase">{{title ?? 'Search User'}}</h2>

<mat-dialog-content>
    <div class="layout-column">

        <mat-form-field appearance="outline">
            <mat-label>Search for user</mat-label>
            <input type="text" matInput [formControl]="searchInput" />
            <mat-hint>Search for ID, user number, national ID, phone or name</mat-hint>
        </mat-form-field>

    </div>

    <div *ngIf="!isLoading; else loading">
        <ng-container *ngIf="result && result.totalUsers > 0; else noResult">

            <table class="table mb-15">

                <tbody>
                    <tr *ngFor="let user of result.users">
                        <td>
                            {{user.name}}<br>
                            <small>{{user.nationalId}}</small>
                        </td>
                        <td>{{user.phoneNumber}}</td>
                        <td class="text-right">
                            <button type="button" mat-button (click)="select(user)"
                                class="btn is-primary mr-5">Select</button>
                        </td>
                    </tr>
                </tbody>

            </table>

            <mat-paginator [length]="result.totalUsers" [pageIndex]="pageEvent.pageIndex"
                [pageSize]="pageEvent.pageSize" [hidePageSize]="true" [showFirstLastButtons]="true"
                (page)="loadAsync($event)">
            </mat-paginator>

        </ng-container>
    </div>

</mat-dialog-content>
<ng-template #loading>
    <ddf-loader message="Searching users"></ddf-loader>
</ng-template>

<ng-template #loading>
    <ddf-loader message="Searching for user {{searchInput.value}}"></ddf-loader>
</ng-template>

<ng-template #noResult>

    <div *ngIf="result && result.totalUsers <= 0">
        <p class="pv-15">
            No matches.
        </p>
    </div>

</ng-template>