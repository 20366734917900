import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '@ddfshared/providers';
import { WrapperComponent } from '@core/components/wrapper';
import { NotFoundComponent } from '@core/components/not-found';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../ddfshared/modules/auth/auth.module').then(x => x.AuthModule)
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '',
        component: WrapperComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'alertcenter',
                loadChildren: () => import('./alertcenter/alertcenter.module').then(m => m.AlertCenterModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path: 'message',
                loadChildren: () => import('./message/message.module').then(m => m.MessageModule),
            },
            {
                path: 'collective',
                loadChildren: () => import('./collective/collective.module').then(m => m.CollectiveModule),
            },
            {
                path: 'company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            },
            {
                path: 'team',
                loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
            },
            {
                path: 'trainer',
                loadChildren: () => import('./trainer/trainer.module').then(m => m.TrainerModule),
            },
            {
                path: 'group',
                loadChildren: () => import('./group/group.module').then(m => m.GroupModule),
            },
            {
                path: 'order',
                loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
            },
            {
                path: 'product',
                loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
            },
            {
                path: 'underwriter',
                loadChildren: () => import('./underwriter/underwriter.module').then(m => m.UnderwriterModule),
            },
            {
                path: 'policy',
                loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
            },
            {
                path: 'claim',
                loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule),
            },
            {
                path: 'ussd',
                loadChildren: () => import('./ussd/ussd.module').then(m => m.UssdModule),
            },
            {
                path: 'organization',
                loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
            },
            {
                path: 'distribution-channel',
                loadChildren: () => import('./distribution-channel/distribution-channel.module').then(m => m.DistributionChannelModule),
            },
            {
                path: 'access',
                loadChildren: () => import('./access/access.module').then(m => m.AccessModule),
            },
            {
                path: 'form',
                loadChildren: () => import('./form/form.module').then(m => m.FormModule),
            },
            {
                path: 'role',
                loadChildren: () => import('./role/role.module').then(m => m.RoleModule),
            },
            {
                path: 'transactions',
                loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
            },
            {
                path: 'invoices',
                loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
            },
            {
                path: 'report',
                loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
            },
            {
                path: 'metrics',
                loadChildren: () => import('./metric/metric.module').then(m => m.MetricModule),
            },
            {
                path: 'payout',
                loadChildren: () => import('./distribution-payout/distribution-payout.module').then(m => m.DistributionPayoutModule),
            },
            {
                path: '**',
                redirectTo: 'dashboard',
            },
        ]
    },
    {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy',
    })],
    exports: [RouterModule]
})
export class AppRoutesModule {}
