import { Pipe, PipeTransform } from '@angular/core';

/*
 * Formats a fullname to inistial
 * Usage:
 *   value | initials
 * Example:
 *   {{ 'John Doe' | initials }}
 *   formats to: JD
*/
@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform
{
    transform(value: string): string
    {
        if (!value) return '--';
        const arr = value.split(' ');
        const result = (arr.length === 1)
            ? arr[0][0] + arr[0][1]
            : [arr[0], arr[arr.length - 1]].map(n => n[0]).join('.')

        return result.toUpperCase();
    }
}