import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app';
import { MatIconRegistry } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutesModule } from './app.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { getIcons } from './icons';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { DDFSharedModule } from '@ddfshared/ddfshared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CoreModule,
        SharedModule,
        DDFSharedModule.forRoot({
            settings: {
                production: environment.production,
                appId: environment.appId,
                envName: environment.envName,
                appUrl: environment.appUrl,
                authUrl: environment.authUrl,
                cbiUrl: environment.cbiUrl,
                commandUrl: environment.commandUrl,
                fileUrl: environment.filesUrl,
                queryUrl: environment.queryUrl,
                reportUrl: environment.reportUrl,
                ssoUrl: environment.ssoUrl
            }
        }),
        AppRoutesModule,
        MatCardModule,
        MatBadgeModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
    ],
    declarations: [
        AppComponent,
        DashboardComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        getIcons().forEach((icon) => {
            if (!icon || icon.length <= 0) {
                return;
            }

            matIconRegistry.addSvgIcon(
                icon,
                domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/svg/${icon}.svg`)
            );
        });
    }
}
