import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@ddfshared/abstracts/base.component';


@Component({
    selector: 'order-create-confirm',
    templateUrl: './confirm.html'
})
export class OrderCreateConfirmComponent extends BaseComponent
{
    @Input() public parent: any;

    public form: UntypedFormGroup;
    public isSubmitted = false;

    constructor()
    {
        super();

        this.form = new UntypedFormGroup({
            confirmed: new UntypedFormControl(false, [Validators.requiredTrue])
        });
    }

    public get isRenewable(): boolean
    {
        return this.parent.product.orderType === 'renewal';
    }

    public async previous(): Promise<void>
    {
        this.parent.previous();
    }

    public get confirmed(): boolean
    {
        return this.form.get('confirmed').value || false;
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();

        if (this.form.invalid)
        {
            return;
        }

        this.isSubmitted = true;

        this.parent.onConfirmSubmit();
    }
}
