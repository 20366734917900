<ng-container *ngIf="isReady; else loading">

    <ng-container *ngIf="!parent.message; else showWarning">

        <ng-container *ngIf="canRenew">

            <ng-container *ngIf="renewableProducts && renewableProducts.length > 0; else noProducts">
    
                <div class="message is-warning">
                    <p>{{parent.customer.name}} is up for renewal. Select renewal package:</p>
                </div>
    
                <table class="table renewProductsTable">
    
                    <thead>
                        <tr>
                            <td>Renew To</td>
                            <td style="width: 100px; text-align: right;">Price</td>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr [class.selectedProduct]="selectedProduct && selectedProduct == renewable.productTag" *ngFor="let renewable of renewableProducts" (click)="selectRenewal(renewable)">
                            <td>{{renewable.productTag}}</td>
                            <td class="text-right">{{renewable.totalPrice | currency: ' KES '}}</td>
                        </tr>
                    </tbody>
    
                </table>
    
            </ng-container>
    
    
        </ng-container>
    
        <ng-container *ngIf="!canRenew">
    
            <ng-container *ngIf="products && products.length > 0; else noProducts">
    
    
                <table class="table orderProductsTable" >
    
                    <thead>
                        <tr>
                            <td>Product</td>
                            <td style="width: 100px; text-align: right;">Price</td>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr [class.selectedProduct]="selectedProduct && selectedProduct == product.id" *ngFor="let product of products" (click)="selectProduct(product)">
                            <td>
                                {{product.name}}<br>
                                <small>{{product.description}}</small>
                            </td>
                            <td class="text-right" style="vertical-align: top;">{{product.price | currency: ' KES '}}</td>
                        </tr>
                    </tbody>
    
                </table>
    
            </ng-container>
    
        </ng-container>
    
        <div class="layout-row layout-align-space-between-start mt-15">
    
            <button type="button" mat-button class="btn" mat-dialog-close>
                Cancel
            </button>
    
            <button type="button" mat-button class="btn" *ngIf="!isEdit" [ngClass]="{'is-primary': !!selectedProduct}" [disabled]="!selectedProduct" (click)="productNext()">
                Next
            </button>

            <button type="button" mat-button class="btn" *ngIf="isEdit" [ngClass]="{'is-green': !!selectedProduct}" [disabled]="!selectedProduct" (click)="productUpdate()">
                Update
            </button>
    
        </div>

    </ng-container>

</ng-container>

<ng-template #loading>
    <ddf-loader></ddf-loader>
</ng-template>

<ng-template #showWarning>

    <p>{{parent.message}}</p>

    <div>
        <button type="button" mat-button class="btn is-primary" mat-dialog-close>
            OK
        </button>
    </div>

</ng-template>

<ng-template #noProducts>
    
    <div class="pv-10">
        <mat-divider></mat-divider>
    </div>

    <p>There are none available products at this time</p>

</ng-template>
