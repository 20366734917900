export const environment = {
    production: false,
    envName: 'default',
    name: 'staging',
    appName: 'DDFinancePortal',
    appId: 1,
    authUrl: 'https://auth.ddf.services',
    appUrl: 'https://portal.ddf.services',
    cbiUrl: 'https://cbi.ddf.services',
    cdnUrl: 'https://cdn.ddfinance.com',
    ssoUrl: 'https://sso.ddf.services',
    commandUrl: 'https://command.ddf.services',
    queryUrl: 'https://query.ddf.services',
    reportUrl: 'https://report.ddf.services',
    socketUrl: 'https://sockets.ddf.services',
    ussdUrl: 'https://ussd.ddf.services',
    filesUrl: 'https://fileserver.ddf.services',
    currencyExchangeUrl: 'https://api.exchangerate.host/latest?base=USD&symbols=KES',
    domain: '.ddf.services',
};
