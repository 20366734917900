import { NgModule } from '@angular/core';
import { SearchUserModule } from '../search-user/search-user.module';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../loader/loader.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmptyListModule } from '../empty-list/empty-list.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrderCreateComponent } from './components/order-create';
import { OrderCreateEditExistingComponent } from "./components/edit-existing";
import { OrderCreateProductComponent } from './components/product';
import { OrderCreateDependantComponent } from './components/dependant';
import { OrderCreateNewDependantComponent } from './components/new-dependant';
import { OrderCreateConfirmComponent } from './components/confirm';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PipesModule } from "../../pipes/pipes.module";
import { Helper } from '../../../app/shared/helper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../order/order.module';
import { MatOptionModule } from '@angular/material/core';

@NgModule({   
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatCheckboxModule,
        MatDividerModule,
        MatDatepickerModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatOptionModule,
        LoaderModule,
        SearchUserModule,
        EmptyListModule,
        PipesModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        OrderCreateComponent,
        OrderCreateEditExistingComponent,
        OrderCreateProductComponent,
        OrderCreateDependantComponent,
        OrderCreateNewDependantComponent,
        OrderCreateConfirmComponent,        
    ],
    exports: [
        OrderCreateComponent
    ],
    providers:[
        Helper
    ]
})
export class OrderCreateDialogModule {}
