import { Component } from '@angular/core';
import { BaseComponent } from '@ddfshared/abstracts';
import { TokenService } from '@ddfshared/providers';
import { UiService, MenuLink } from '@core/providers';
import { Identity } from '@ddfshared/providers';

@Component({
    templateUrl: './dashboard.component.html',
    styles: []
})
export class DashboardComponent extends BaseComponent {

    public identity: Identity | undefined;
    public menuLinks: MenuLink[] | undefined;

    constructor(
        private uiService: UiService,
        private tokenService: TokenService) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();
        
        this.uiService.removeFilterSettingsFromLocalStorage();
        this.identity = this.tokenService.getIdentity();
        this.menuLinks = this.uiService.getMenuLinks();
    }
}
