import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'ddf-back-button',
    template: `
        <a mat-icon-button (click)="onClick($event)">
            <mat-icon svgIcon="chevron-left"></mat-icon>
        </a>
    `
})
export class BackButtonComponent implements OnInit
{
    @Input('link') public returnUrl: string = null;

    constructor(
        private location: Location,
        private router: Router
    ) { }

    public ngOnInit(): void {

    }

    public onClick(ev: Event): Promise<boolean>
    {
        ev.preventDefault();

        if (this.returnUrl)
        {
            return this.router.navigateByUrl(this.returnUrl);
        }

        this.location.back();
        return Promise.resolve(true);
    }
}
