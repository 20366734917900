import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRepository, CheckIdentityModel } from '@ddfshared/repositories/user.repository';
import { Dependant } from '@ddfshared/repositories/sale.repository';
import { OrderComponent, UserSelect } from './order.component';
import * as dayjs from 'dayjs';
import { Helper } from '@ddfshared/../app/shared/helper';


@Component({
    selector: 'order-dependant',
    templateUrl: 'order-dependant.component.html'
})
export class OrderDependantComponent
{
    @Input() 
    public parent: OrderComponent;
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public skipIprs: boolean = false;
    public ignorePrevious: boolean = false;
    public clickedNext: boolean = false;
    public details: any;
    public dependants: Dependant[] = [];
    private dependant: Dependant | null = null;

    constructor(private userService: UserRepository, private helper: Helper)
    {
        this.helper.init();
        this.minDate = dayjs.utc().subtract(18, 'year').toDate();
        this.maxDate = dayjs.utc().subtract(1, 'year').toDate();

        const pattern =  /^[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            name: new UntypedFormControl(null),
            dateOfBirth: new UntypedFormControl(null),
            skipIprs: new UntypedFormControl(false),
            isBeneficiary: new UntypedFormControl(false),
        });
    }

    public async ngOnInit(): Promise<void>
    {

        if (this.details)
        {
            this.form.get('idNumber').setValue(this.details.idNumber);
        }

        if (!this.parent.isForJunior)
        {
            this.initForBundle();
        }

        if (this.parent.edit) this.ignorePrevious = true;
    }


    public async previous(): Promise<void>
    {
        this.parent.previous();
    }

    public initForBundle(): void 
    {
        const ctrlPhone = new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\+254[0-9]{7,12}$/)]);
        this.form.addControl('phone', ctrlPhone);
        if (this.details) this.form.get('phone').setValue(this.details.phone);
    }


    private juniorSkipIprs()
    {
        this.skipIprs = true;

        const nameControl = this.form.get('name');
        nameControl.setValidators([Validators.required]);
        nameControl.updateValueAndValidity();

        const dobControl = this.form.get('dateOfBirth');
        dobControl.setValidators([Validators.required]);
        dobControl.updateValueAndValidity();
    }


    public async submit(ev: Event): Promise<void>
    {
        ev.preventDefault();

        if (this.form.invalid) return;

        this.message = null;
        this.clickedNext = true;

        const data: CheckIdentityModel =
        {
            idType: this.parent.isForJunior ? 2 : 1,
            idNumber: this.form.value['idNumber'],
            phone: this.form.value['phone'] || null,
            extendedResult: false,
            includeLocalUsers: true,
            createIfNotFound: true
        };

        const idCheck = await this.userService.checkIdentityAsync(data);

        switch (idCheck.status)
        {
            case -6: // Exception
                //this.message = 'An unknown error occured. Try again or contact support';
            
                if (this.parent.isForJunior && this.skipIprs)
                {
                    this.dependant =
                    {
                        idNumber: this.form.value['idNumber'],
                        name: this.form.value['name'],
                        dateOfBirth: this.form.value['dateOfBirth'],
                        skipIprs: this.skipIprs,
                        isJunior: true,
                        isBeneficiary: false,
                    }

                    this.parent.onBeneficiarySelect(this.parent.policyholder.id);

                    this.submitDependants();
                    break;
                } 
                else 
                {
                    this.juniorSkipIprs();
                }
                break;

            case -5: // TimeoutException
                this.message = this.helper.translate('timeoutNationalRegitstry');
                break;

            case -4: // UserRegisteredWithDifferentPhone
                this.message = this.helper.translate('registeredUser');
                break;

            case -3: // PhoneNumberTaken
                this.message = this.helper.translate('registeredPhoneNumber');
            
                break;

            case -2: // PhoneInvalid
                this.message = this.helper.translate('aValidPhoneNumberStartsWith254Or257');
                break;
            case 2: // FoundRemotely
                if (this.parent.isForJunior)
                {

                    this.dependant =
                    {
                        idNumber: idCheck.idNumber,
                        name: idCheck.details.name,
                        dateOfBirth: new Date(idCheck.details.dateOfBirth),
                        skipIprs: false,
                        isJunior: true,
                        isBeneficiary: false,
                    }

                    this.parent.onBeneficiarySelect(this.parent.policyholder.id);
                }
                else
                {
                    this.dependant =
                    {
                        name: idCheck.details.name,
                        idNumber: idCheck.idNumber,
                        userId: idCheck.details.userId,
                        phone: this.form.value['phone'],
                        isJunior: false,
                        isBeneficiary: true,
                        skipIprs: this.skipIprs,
                    }
                }

                this.submitDependants();
                break;
            case 1: // FoundLocally
            
                const currentPolicyholder: UserSelect = this.parent.policyholder;
                const userSelect: UserSelect =
                {
                    id: idCheck.details.userId,
                    name: idCheck.details.name
                };

                // check spouse details differ from policyholder details
                if (currentPolicyholder.id === userSelect.id) 
                {
                    this.message = this.helper.translate('duplicateDetails', {record: this.helper.translate('beneficiary')});
                    break;
                }

                // check details does not have an active sale or active policy
                const response = await this.userService.getProfileShort(userSelect.id);                
                if(!response.isSuccess) 
                {
                    // users registered by different user
                    // a trainer may not have acess to some previously registered members
                    this.message = this.helper.translate('registeredUser');
                    break;
                }
                const profile = response.result;
                if (profile.hasPendingSales || profile.hasActivePolicies)
                {
                    this.message = this.helper.translate('hasActivePoliciesAndSales');
                    break;
                }

                this.dependant =
                {
                    name: idCheck.details.name,
                    idNumber: idCheck.idNumber,
                    userId: idCheck.details.userId,
                    phone: this.form.value['phone'],
                    isJunior: false,
                    isBeneficiary: true,
                    skipIprs: this.skipIprs,
                }

                if (this.dependant.userId) this.parent.onBeneficiarySelect(this.dependant.userId);


                if (this.parent.isForJunior)
                {
                    this.dependant =
                    {
                        idNumber: this.form.value['idNumber'],
                        name: this.form.value['name'],
                        dateOfBirth: this.form.value['dateOfBirth'],
                        skipIprs: this.skipIprs,
                        isJunior: true,
                        isBeneficiary: false,
                    }

                    this.parent.onBeneficiarySelect(this.parent.policyholder.id);
                }

                if (!this.parent.isForSpouse && !this.parent.isForJunior) // Normal
                {
                    this.dependant =
                    {
                        skipIprs: this.skipIprs,
                        name: idCheck.details.name,
                        idNumber: idCheck.idNumber,
                        userId: idCheck.details.userId,
                        phone: this.form.value['phone'] || null,
                        isJunior: false,
                        isBeneficiary: true,
                    }

                    if (this.dependant.userId) this.parent.onBeneficiarySelect(this.dependant.userId);
                }

                this.submitDependants();
                break;
            case 0: // NotFound
                if (this.parent.isForJunior && this.skipIprs)
                {
                    this.dependant =
                    {
                        idNumber: this.form.value['idNumber'],
                        name: this.form.value['name'],
                        dateOfBirth: this.form.value['dateOfBirth'],
                        skipIprs: this.skipIprs,
                        isJunior: true,
                        isBeneficiary: false,
                    }

                    this.parent.onBeneficiarySelect(this.parent.policyholder.id);

                    this.submitDependants();
                    break;
                }

                this.parent.isForJunior ? this.juniorSkipIprs() : this.message = this.helper.translate('identityNotFound');
                break;
        
        }
        this.clickedNext = false;
    }

    private submitDependants(): void
    {
        this.dependants.push(this.dependant);
        this.parent.onDependantsSubmit(this.dependants);
        this.parent.next();
    }

}
