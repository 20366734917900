import { Injectable } from "@angular/core";
import { QueryService } from "@ddfshared/providers";

@Injectable({providedIn: 'any'})
export class ElearningRepository
{
    constructor(private queryService: QueryService) {}

    public async getResults(id: any): Promise<any>
    {
        const response = await this.queryService.queryAsync("ElearningQuery", {nationalId: id});
        return response.result;
    }
}

