import { NgModule } from '@angular/core';
import { SearchUserModule } from '../search-user/search-user.module';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../loader/loader.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrderComponent } from './components/order.component';
import { OrderConfirmComponent } from './components/order-confirm.component';
import { OrderDependantComponent } from './components/order-dependant.component';
import { OrderEditComponent } from './components/order-edit.component';
import { OrderProductComponent } from './components/order-product.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrderNewDependantComponent } from './components/order-new-dependant.component';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { Helper } from '../../../app/shared/helper';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import { PipesModule } from "../../pipes/pipes.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatCheckboxModule,
        MatDividerModule,
        MatDatepickerModule,
        MatRadioModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSnackBarModule,
        LoaderModule,
        SearchUserModule,
        MatSelectModule,
        MatOptionModule,
        PipesModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        Helper
    ],
    declarations: [
        OrderComponent,
        OrderConfirmComponent,
        OrderDependantComponent,
        OrderEditComponent,
        OrderProductComponent,
        OrderNewDependantComponent,
    ],
    exports: [
        OrderComponent,
        OrderConfirmComponent,
        OrderDependantComponent,
        OrderEditComponent,
        OrderProductComponent,
    ]
})
export class OrderModule {}
