import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/** Phone validator 
 * matches phone numbers of form 
 * Kenyan
 * 0712345678
 * 254712345678
 * +254712345678
 * returns either null if the control value is valid or a validation error object
*/
export function phoneNumberValidator(): ValidatorFn
{
    return (control: AbstractControl): null | ValidationErrors =>
    {

        let value = control.value;
        let errors: ValidationErrors = { message: 'Phone number is invalid' }

        if (value)
        {
            let phone: string = value.toString();
            if (phone.startsWith('0'))
            {
                phone = phone.slice(1);
            }
            // min 712345678
            if (phone.startsWith('7') && phone.length < 9) 
            {
                errors.message = 'Phone number is too short';
                return errors;
            }

            if (phone.startsWith('7'))
            {
                phone = `254${phone}`
            }

            const prefixes = ['110', '111', '112', '113', '114', '115']
            let sub = phone.substring(0, 3)

            if (prefixes.findIndex(prefix => sub === prefix) !== -1)
            {
                phone = `${phone}`
                if (phone.length < 11)
                {
                    errors.message = 'Phone number is too short';
                    return errors;
                }
                else if (phone.length > 11)
                {
                    errors.message = 'Phone number is too long';
                    return errors;
                } 
                else
                {
                    return null;
                }
            }

            // +254712345678
            if (phone.startsWith('+254'))
            {
                phone = phone.slice(1);
            }

            // min 254712345678
            if (phone.startsWith('254') && phone.length < 12)
            {
                errors.message = 'Phone number is too short';
                return errors;
            }

            // max 254712345678
            if (phone.startsWith('254') && phone.length > 12)
            {
                errors.message = 'Phone number is too long';
                return errors;
            }

            if (phone.startsWith('254'))
            {
                const reg: RegExp = /^254[0-9]{7,13}$/;
                const validation = reg.test(phone);
                return validation ? null : errors;
            }
        }

        return errors;
    };
}