import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
    name: 'defaultCurrency',
  })
export class DefaultCurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'KES ',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '1.2-2',
        locale: string = 'en',
    ): string | null {
        return formatCurrency(
          value,
          locale,
          `${currencyCode} `,
          currencyCode,
          digitsInfo,
        );
    }

    
}