<form [formGroup]="form" (submit)="submit($event)">

    <div class="layout-column">

        <mat-form-field appearance="outline" class="flex">
            <mat-label>{{parent.isForJunior ? 'Birth Certificate Number' : parent.isForSpouse ? 'Spouse National ID' : 'National ID'}}</mat-label>
            <input matInput formControlName="idNumber"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex" *ngIf="skipIprs">
            <mat-label>Name Of Child</mat-label>
            <input matInput formControlName="name"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex" *ngIf="skipIprs"> 
            <mat-label>Date of Birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>Date of Birth is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex" *ngIf="form.get('phone')">
            <mat-label>{{parent.isForSpouse ? 'Spouse Phone' : 'Beneficiary Phone'}}</mat-label>
            <input matInput formControlName="phone"/>
            <mat-error>Phone Number should start with +254</mat-error>
        </mat-form-field>

        <div class="message is-error mb-15" *ngIf="message">{{message}}</div>
    </div>

    <div class="layout-row layout-align-space-between-start">

        <button type="button" mat-button class="btn" (click)="previous()" [disabled]="ignorePrevious">
            Previous
        </button>

        <button type="submit" mat-button class="btn is-primary" [disabled]="clickedNext">
            Next
        </button>

    </div>

</form>
