import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '../providers';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface OrganizationModel
{
    id: string;
    name: string;
    slug: string;
    createdAt: Date;
    collectives: CollectiveModel[];
    roles: RoleModel[];
    numApprovedMembers: number;
}


export interface CollectiveModel
{
    id: string;
    createdAt: Date;
    name: string;
    code: string;
    numApproved: number;

}

export interface RoleModel
{
    id: string;
    createdAt: Date;
    name: string;
    numMembers: number;
}

export interface IOrganization
{
    id: string;
    name: string;
}


export interface OrganizationQuery
{
    sortBy?: string;
    orderBy?: string;
}

export interface OrganizationsQueryResult
{
    organizations: IOrganization[];
    totalCount: number;
}

@Injectable({ providedIn: 'any' })
export class OrganizationRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public async searchOrganizations(): Promise<IQueryResponse<OrganizationsQueryResult>>
    {
        return this.queryService.queryAsync('OrganizationSearchQuery', {});
    }

    public create(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('OrganizationCreate', data);
    }

    public async getOrganizations(data: OrganizationQuery): Promise<IQueryResponse<OrganizationsQueryResult>>
    {
        return this.queryService.queryAsync('OrganizationsQuery', data);
    }

    public async getOrganization(organizationId: string): Promise<IQueryResponse<OrganizationModel>>
    {
        return this.queryService.queryAsync('OrganizationQuery', { organizationId });

    }
}
