<form [formGroup]="form" (submit)="submit($event)">

    <div class="tw-flex tw-flex-col">

        <mat-form-field appearance="outline" class="tw-flex">            
            <mat-label *ngIf="parent.isForJunior">{{ 'birthCertificate' | translate}} {{'number' | translate  }} </mat-label>
            <mat-label *ngIf="parent.isForSpouse"> {{'spouse' | translate}} {{'nationalId' | translate}} </mat-label>
            <input matInput formControlName="idNumber"/>
            <mat-error>{{'required' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="tw-flex" *ngIf="skipIprs">
            <mat-label>{{'nameOfChild' | translate}}</mat-label>
            <input matInput formControlName="name"/>
            <mat-error>{{ 'nameOfChild' | translate }} {{ 'isRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="tw-flex" *ngIf="skipIprs"> 
            <mat-label>{{ 'dateOfBirth' | translate }}</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>{{ 'dateOfBirth' | translate }} {{ 'isRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="tw-flex" *ngIf="form.get('phone')">
            <mat-label>{{parent.isForSpouse ? 'Spouse Phone' : 'Beneficiary Phone'}}</mat-label>
            <input matInput formControlName="phone"/>
            <mat-error>{{'aValidPhoneNumberStartsWith254Or257' | translate}}</mat-error>
        </mat-form-field>

        <div class="message tw-mb-4" *ngIf="message">{{message}}</div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" mat-button class="btn" (click)="previous()" [disabled]="ignorePrevious">
            {{ 'previous' | translate }}
        </button>

        <button type="submit" mat-button class="btn" [disabled]="clickedNext">
            {{ 'next' | translate }}
        </button>

    </div>

</form>
