import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionLink, UiService } from '@core/providers';

export interface TabLink
{
    path: string[];
    label: string;
}

@Component({
    selector: 'ddf-template',
    templateUrl: './ddf-template.html'
})
export class TemplateComponent
{
    @Input() title: string = null;
    @Input() pretitle: string = null;
    @Input() posttitle: string = null;
    @Input() returnUrl: string = null;
    @Input() showOptions = false;
    @Input() forceReturnLink = false;
    @Input() tabs: TabLink[] = [];
    
    constructor(private uiService: UiService) {}

    public get actionLinks$(): Observable<ActionLink[]> {
        return this.uiService.actionLinks$;
    }

    public onClick(actionLink: ActionLink): Promise<any>
    {
        return actionLink.onClick();
    }
}
