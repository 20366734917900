<form [formGroup]="form" (submit)="submitAsync($event)">

    <div class="layout-column">

        <mat-form-field appearance="outline" class="flex">
            <mat-label>National ID</mat-label>
            <input type="text" matInput formControlName="idNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="familyName"/>
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Date of Birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>Date of Birth is required</mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
            <mat-label>Select Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="1">Male</mat-option>
              <mat-option value="2">Female</mat-option> 
            </mat-select> 
          </mat-form-field> 

        <mat-form-field appearance="outline" class="flex">
            <mat-label>Hauge Customer Id</mat-label>
            <input type="text" matInput formControlName="haugeCustomerId" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>Phone Number</mat-label>
            <input type="tel" placeholder="" matInput formControlName="phone">
        </mat-form-field>

        <mat-checkbox *ngIf="memberCheckbox" formControlName="addUser">  Add as Collective member </mat-checkbox>

        <div class="message is-error mb-15" *ngIf="message">{{message}}</div>

    </div>

    <div class="layout-row layout-align-space-between-center">
        <mat-spinner [ngStyle]="{'visibility':isChecking ? 'visible' : 'hidden'}" [diameter]="24"></mat-spinner>
        <button type="submit" mat-stroked-button [disabled]="form.invalid || isChecking"
            [ngClass]="{'is-primary': form.valid}">
            Next
        </button>
    </div>
</form>