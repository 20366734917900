import { Component, Inject, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "@ddfshared/abstracts";
import { DDF_SHARED_CONFIG, DDFSharedConfig } from "@ddfshared/ddfshared.config";
import { DateService } from "@ddfshared/providers";
import { UserRepository } from "@ddfshared/repositories";
import { CheckIdentityModel } from "@ddfshared/repositories/user.repository";
import { Subject } from "rxjs";
import { IdentityModel } from "./add-identity.dialog";

@Component({
    selector: 'add-identity-junior',
    templateUrl: './add-identity-junior.component.html'
})
export class AddIdentityJuniorComponent extends BaseComponent
{
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public skipIprs: boolean = false;
    public isChecking: boolean = false;

    @Output() public onSelected = new Subject<IdentityModel>();

    constructor(
        @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig,
        private dateService: DateService,
        private userRepository: UserRepository)
    {
        super();

        this.minDate = this.dateService.dayjs.utc().subtract(24, 'year').toDate();
        this.maxDate = this.dateService.dayjs.utc().subtract(1, 'month').toDate();

        const pattern = this.config.settings.production
            ? /^[0-9]{4,10}$/
            : /^(\#\!)?[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            name: new UntypedFormControl(null),
            dateOfBirth: new UntypedFormControl(null),
            skipIprs: new UntypedFormControl(false)
        });
    }

    private setupSkipIprs(): void
    {
        this.skipIprs = true;

        const nameControl = this.form.get('name');
        nameControl.addValidators([Validators.required]);
        nameControl.markAsPristine();
        nameControl.updateValueAndValidity();

        const dobControl = this.form.get('dateOfBirth');
        dobControl.addValidators([Validators.required]);
        dobControl.updateValueAndValidity();
        dobControl.markAsPristine();
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.isChecking = true;

        if (this.form.invalid) return;

        this.message = null;

        const data: CheckIdentityModel =
        {
            idType: 2,
            idNumber: this.form.get('idNumber').value,
            extendedResult: false,
            includeLocalUsers: true,
            createIfNotFound: false
        };

        const idCheck = await this.userRepository.checkIdentityAsync(data);
        switch (idCheck.status)
        {
            case -6: // Exception
                this.message = 'An unknown error occurred. Try again or contact support';
                break;
            case -5: // TimeoutException
                this.message = 'The request to national registry timed out. Try again or contact support';
                break;
            case 0: // NotFound
                if (this.skipIprs)
                {
                    this.onSelected.next({
                        idNumber: this.form.value['idNumber'],
                        name: this.form.value['name'],
                        dateOfBirth: this.form.value['dateOfBirth'],
                        skipIprs: this.skipIprs
                    })
                }
                else
                {
                    this.setupSkipIprs();
                }
                break;
            case 2: // FoundRemotely
                this.onSelected.next({
                    idNumber: idCheck.idNumber,
                    name: idCheck.details.name,
                    dateOfBirth: idCheck.details.dateOfBirth,
                    skipIprs: this.skipIprs
                });
                break;
            default:
                this.message = 'The identity was not found';
                break;
        }

        this.isChecking = false;
    }
}
