import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailableProduct, AvailableProductsCollective, ProductRepository } from '@ddfshared/repositories/product.repository';

@Component({
    selector: 'product',
    templateUrl: 'product.component.html'
})
export class ProductComponent
{

    @Input()
    private products: string;


    @Output()
    public productEvent = new EventEmitter<string>();

    // public products: AvailableProduct[];
    public isReady: boolean = false;


    constructor(private productRepository: ProductRepository) { }

    public async ngOnInit(): Promise<void>
    {   
        this.isReady = true;
    }



    public selectProduct(product: AvailableProduct): void
    {

        this.productEvent.emit(product.id);
    }
    
}
