import { Component, Input } from '@angular/core';
import { OrderCreateComponent } from './order-create';
import { MatDialogRef } from '@angular/material/dialog';
import { AvailableProduct, AvailableProducts, AvailableProductsQueryModel, ProductRepository } from '@ddfshared/repositories/product.repository';
import { BaseComponent } from '@ddfshared/abstracts';

export interface ProductSelect
{
    orderType: number;
    productType: string;
    productId: string;
    productName: string;
    productPrice: number;
    productCurrency: string;
}

@Component({
    selector: 'order-create-product',
    templateUrl: './product.html',
    styles: [
        `
            .table tbody tr small {display:none;}
            .table tbody tr.selectedProduct td
            {
                background-color: #d8e6cc !important;
                color: #3b5524 !important;
            }
            .table tbody tr.selectedProduct small {
                display:block;
                white-space: pre-wrap;
            }
        `
    ]
})
export class OrderCreateProductComponent extends BaseComponent
{
    @Input()
    public parent: any;
    public result: AvailableProductsQueryModel;
    public confirmMove = false;
    public isReady = false;
    public isEdit = false;

    constructor(
        private productRepository: ProductRepository,
        private dialogRef: MatDialogRef<OrderCreateComponent>
    )
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();

        if (!this.parent.policyholder)
        {
            this.dialogRef.close();
            return;
        }

        if(this.parent._edit)
        {
            this.isEdit = this.parent._edit;
        }

        const data: AvailableProducts =
        {
            userId: this.parent.policyholder.id,
            editSale: this.parent._edit
        };

        const response = await this.productRepository.getAvailableProducts(data);

        if (response.isSuccess)
        {
            this.result = response.result;
        }
        else
        {
            this.parent.message = response.message || 'An error occurred';
        }

        this.isReady = true;
    }

    public get availableProducts(): AvailableProduct[]
    {
        return this.result && this.result.availableProducts;
    }

    public get selectedProduct(): any
    {
        if (!this.parent.product) return null;
        return this.parent.product.productId;
    }

    public get products(): any[]
    {
        return this.availableProducts;
    }

    public get currentProducts(): any[]
    {
        return this.result && this.result.currentProducts;
    }

    public get canRenew(): boolean
    {
        return this.result && this.result.canRenew;
    }

    public get renewableProducts(): any[]
    {
        return this.result && this.result.renewableProducts || [];
    }

    public async selectRenewal(renewal: any): Promise<void>
    {
        const selection: ProductSelect =
        {
            orderType: 2,
            productType: renewal.productType,
            productId: renewal.id,
            productName: renewal.name,
            productCurrency: renewal.currency,
            productPrice: renewal.price
        }
        this.parent.onProductSelect(selection);
    }

    public async selectProduct(product: any): Promise<void>
    {

        const selection: ProductSelect = {
            orderType: 1,
            productType: product.productType,
            productId: product.id,
            productName: product.name,
            productCurrency: product.currency,
            productPrice: product.price
        }

        this.parent.onProductSelect(selection);

    }

    public async productNext(): Promise<void>
    {
        if (!this.selectProduct) return;
        this.parent.next();
    }

    public async productUpdate(): Promise<void>
    {
        this.parent.onConfirmSubmit();
        this.dialogRef.close();
    }
}
