import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointService } from './breakpoint.service';

@Injectable({providedIn: 'any'})
export class SidenavService
{
    private _currentBreakpoint: string | null = null;
    private _isOpen = false;

    constructor(
        private breakpointService: BreakpointService
    )
    {
    }

    public open(): void
    {
        this._isOpen = true;
    }

    public close(): void
    {
        this._isOpen = false;
    }

    public toggle(): void
    {
        this._isOpen = !this._isOpen;
    }

    public get isOpen(): boolean
    {
        return this._isOpen;
    }

    public get sidenavClass$(): Observable<string>
    {
        return this.breakpointService.current$.pipe(
            map(current => {
                this._currentBreakpoint = current;
                return current ? `is-${current}` : '';
            })
        );
    }
}
