import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GeolocationRepository } from '@ddfshared/repositories';
import { ProvinceComponent } from '@ddfshared/modules/geolocation/components/province.component';


@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
    providers: [
        GeolocationRepository,
    ],
    declarations: [
        ProvinceComponent
    ],
    exports: [
        ProvinceComponent
    ]
})
export class GeolocationModule { }
