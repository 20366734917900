
<div class="tw-py-8 tw-bg-white">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 172 172" width="48" height="48">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                font-family="none" font-weight="none" font-size="none" text-anchor="none"
                style="mix-blend-mode: normal">
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g>
                    <path
                        d="M105.48438,22.84375h23.51563c7.39063,0 13.4375,6.04688 13.4375,13.4375v116.90625c0,3.7625 -2.95625,6.71875 -6.71875,6.71875h-92.71875c-7.39062,0 -13.4375,-6.04687 -13.4375,-13.4375v-110.1875c0,-7.39062 6.04688,-13.4375 13.4375,-13.4375h8.73438"
                        fill="#ffffff"></path>
                    <path
                        d="M102.125,163.9375h-59.125c-9.675,0 -17.46875,-7.79375 -17.46875,-17.46875v-110.1875c0,-9.675 7.79375,-17.46875 17.46875,-17.46875h6.71875c2.28438,0 4.03125,1.74687 4.03125,4.03125c0,2.28438 -1.74687,4.03125 -4.03125,4.03125h-6.71875c-5.24063,0 -9.40625,4.16563 -9.40625,9.40625v110.1875c0,5.24062 4.16562,9.40625 9.40625,9.40625h59.125c2.28437,0 4.03125,1.74687 4.03125,4.03125c0,2.28438 -1.74688,4.03125 -4.03125,4.03125z"
                        fill="#1d3d6d"></path>
                    <path
                        d="M96.75,31.57813h-21.5c-5.24062,0 -9.40625,-4.16563 -9.40625,-9.40625v0c0,-5.24063 4.16563,-9.40625 9.40625,-9.40625h21.5c5.24063,0 9.40625,4.16562 9.40625,9.40625v0c0,5.24062 -4.16562,9.40625 -9.40625,9.40625z"
                        fill="#ffffff"></path>
                    <path
                        d="M129,18.8125h-19.21563c-1.47812,-5.77813 -6.71875,-10.07812 -13.03437,-10.07812h-21.5c-7.39062,0 -13.4375,6.04688 -13.4375,13.4375c0,7.39063 6.04688,13.4375 13.4375,13.4375h21.5c5.77813,0 10.61563,-3.62813 12.63125,-8.73437h19.61875c5.24062,0 9.40625,4.16563 9.40625,9.40625v53.75c0,2.28437 1.74687,4.03125 4.03125,4.03125c2.28438,0 4.03125,-1.74688 4.03125,-4.03125v-53.75c0,-9.675 -7.79375,-17.46875 -17.46875,-17.46875zM96.75,27.54688h-21.5c-2.95625,0 -5.375,-2.41875 -5.375,-5.375c0,-2.95625 2.41875,-5.375 5.375,-5.375h21.5c2.95625,0 5.375,2.41875 5.375,5.375c0,2.95625 -2.41875,5.375 -5.375,5.375z"
                        fill="#1d3d6d"></path>
                    <path
                        d="M123.43177,118.06012c-10.49516,10.49552 -10.49486,27.51183 0.00066,38.00699c10.49552,10.49516 27.51183,10.49486 38.00699,-0.00066c10.49516,-10.49552 10.49486,-27.51183 -0.00066,-38.00699c-10.49552,-10.49516 -27.51183,-10.49486 -38.00699,0.00066z"
                        fill="#cc0025"></path>
                    <path
                        d="M151.70938,141.09375h-18.8125c-2.28437,0 -4.03125,-1.74687 -4.03125,-4.03125c0,-2.28438 1.74688,-4.03125 4.03125,-4.03125h18.8125c2.28438,0 4.03125,1.74687 4.03125,4.03125c0,2.28438 -1.74687,4.03125 -4.03125,4.03125z"
                        fill="#ffffff"></path>
                </g>
            </g>
        </svg>
        <h3 class="tw-text-brand-default tw-text-center tw-font-semibold">{{text}}</h3>
    </div>
</div>
