import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CollectivesPayoutRepository } from './repositories/collectivespayout.repository';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DDFSharedConfig, DDF_SHARED_CONFIG } from './ddfshared.config';
import { LoaderModule } from './modules/loader/loader.module';
import { FilterDialogModule } from './modules/filter-collective-dialog/filter.dialog.module';
import { EditMemberModule } from './modules/edit-member/edit-member.module';
import { TokenService } from './providers/token.service';
import { AuthGuard } from './providers/auth.guard';
import { BreakpointService } from './providers/breakpoint.service';
import { CommandService } from './providers/command.service';
import { DateService } from './providers/date.service';
import { QueryService } from './providers/query.service';
import { SidenavService } from './providers/sidenav.service';
import { AuthRepository } from './repositories/auth.repository';
import { ClaimRepository } from './repositories/claim.repository';
import { CollectiveRepository } from './repositories/collective.repository';
import { CurrencyRepository } from './repositories/currency.repository';
import { MessageRepository } from './repositories/message.repository';
import { MetricRepository } from './repositories/metric.repository';
import { OrderRepository } from './repositories/order.repository';
import { OrganizationRepository } from './repositories/organization.repository';
import { PolicyRepository } from './repositories/policy.repository';
import { ProductRepository } from './repositories/product.repository';
import { RoleRepository } from './repositories/role.repository';
import { TransactionRepository } from './repositories/transaction.repository';
import { UserRepository } from './repositories/user.repository';
import { GeolocationRepository } from './repositories/geolocation.repository';
import { HospitalRepository } from './repositories/hospital.repository';
import { IllnessRepository } from './repositories/illness.repository';
import { OrderCreateDialogModule } from './modules/order-create-dialog/order-create-dialog.module';
import { ChangePasswordModule } from './modules/change-password/change-password.module';
import { SearchUserModule } from './modules/search-user/search-user.module';
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module';
import { TeamRepository } from './repositories/team.repository';
import { SelectProductModule } from './modules/select-product/select-product.module';
import { SaleRepository } from './repositories/sale.repository';
import { SendPaymentDialogModule } from './modules/send-payment-dialog/send-payment-dialog.module';
import { PipesModule } from './pipes/pipes.module';
import { GroupService } from './providers/group.service';
import { SkeletonModule } from './modules/skeleton/skeleton.module';
import { OrderModule } from './modules/order/order.module';
import { ProductModule } from './modules/product/product.module';
import { EmptyListModule } from './modules/empty-list/empty-list.module';
import { GeolocationModule } from './modules/geolocation/geolocation.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
    ],
    declarations: [],
    exports: [
        ChangePasswordModule,
        ConfirmDialogModule,
        EditMemberModule,
        EmptyListModule,
        FilterDialogModule,
        GeolocationModule,
        LoaderModule,
        OrderModule,
        OrderCreateDialogModule,
        PipesModule,
        ProductModule,
        SearchUserModule,
        SelectProductModule,
        SendPaymentDialogModule,
        SkeletonModule,
    ]
})
export class DDFSharedModule
{

    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer)
    {

        const icons = ['eye', 'eye-off'];

        icons.forEach((icon) =>
        {
            if (!icon || icon.length <= 0) { return; }
            matIconRegistry.addSvgIcon(
                icon, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/svg/${icon}.svg`)
            );
        });
    }

    static forRoot(config: DDFSharedConfig): ModuleWithProviders<DDFSharedModule>
    {
        return {
            ngModule: DDFSharedModule,
            providers: [
                {
                    provide: DDF_SHARED_CONFIG,
                    useValue: config
                },
                AuthGuard,
                BreakpointService,
                CommandService,
                DateService,
                GroupService,
                QueryService,
                SidenavService,
                TokenService,
                AuthRepository,
                ClaimRepository,
                CollectiveRepository,
                GeolocationRepository,
                CurrencyRepository,
                MessageRepository,
                MetricRepository,
                CollectivesPayoutRepository,
                PolicyRepository,
                ProductRepository,
                OrderRepository,
                OrganizationRepository,
                PolicyRepository,
                ProductRepository,
                RoleRepository,
                TransactionRepository,
                UserRepository,
                TeamRepository,
                SaleRepository,
                HospitalRepository,
                IllnessRepository
            ]
        };
    }
}
