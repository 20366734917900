import { Component, Inject, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateService } from '@ddfshared/providers';
import { DDFSharedConfig, DDF_SHARED_CONFIG } from '@ddfshared/ddfshared.config';
import { UserRepository } from '@ddfshared/repositories';
import { BaseComponent } from '@ddfshared/abstracts/base.component';
import { Dependant } from '@ddfshared/repositories/sale.repository';
import { UserSelect } from './order-create';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';

@Component({
    selector: 'order-new-dependant',
    templateUrl: './new-dependant.html'
})
export class OrderCreateNewDependantComponent extends BaseComponent
{
    @Input() public parent: any;
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public skipIprs: boolean = false;
    public ignorePrevious: boolean = false;
    public clickedNext: boolean = false;
    public details: any;
    public dependants: Dependant[] = [];
    private dependant: Dependant | null = null;
    private shortuuid : string = '';

    constructor(
        @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig,
        private dateService: DateService,
        private userRepository: UserRepository
    )
    {
        super();
        
        this.maxDate = this.dateService.dayjs.utc().toDate();          

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null,[Validators.required]),         
            firstName: new UntypedFormControl(null,[Validators.required]),
            familyName : new UntypedFormControl(null,[Validators.required]),
            dateOfBirth: new UntypedFormControl(null,[Validators.required]),              
            skipIprs: new UntypedFormControl(false),
            isBeneficiary: new UntypedFormControl(false),
        });
    }
    
    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();

         if (this.details)
        {
            this.form.get('idNumber').setValue(this.details.idNumber);
        }
        if (this.parent.isForJunior) {            
            this.form.get('idNumber').removeValidators([Validators.required])
        }

        if (!this.parent.isForJunior)
        {
            this.initForBundle();
        }
        if (this.parent.collective.countryCode == "BI" && this.parent.isForJunior){

            this.shortuuid = uuidv4().substring(0, 8);
        }
        if (this.parent.isForJunior) {
          this.minDate = this.dateService.dayjs.utc().subtract(19, 'year').add(1,'day').toDate();
        }

        if (this.parent.edit) this.ignorePrevious = true;
    }


    public async previous(): Promise<void>
    {
        this.parent.previous();
    }

    public initForBundle(): void
    {
        // Todo change to support burundi
        const ctrlPhone = new UntypedFormControl(null, [Validators.pattern(/^\+257[0-9]{7,12}$/)]);
        this.form.addControl('phone', ctrlPhone);
       
       
        if (this.details) this.form.get('phone').setValue(this.details.phone);
    }


    private juniorSkipIprs()
    {
        this.skipIprs = true;

        const dobControl = this.form.get('dateOfBirth');
        dobControl.setValidators([Validators.required]);
        dobControl.updateValueAndValidity();
    }


    public async submit(ev: Event): Promise<void>
    {
        
        ev.preventDefault();

        

        if (this.form.invalid) return;

        this.message = null;
        this.clickedNext = true;

        
        const data =
        {
            idType: this.parent.isForJunior ? 2 : 1,
            nationalId: this.form.value['idNumber'],
            phoneNumber: this.form.value['phone'] || null,
            extendedResult: false,
            includeLocalUsers: true,
            createIfNotFound: true,
            dateOfBirth:this.form.value['dateOfBirth'],
            firstName:this.form.value['firstName'],
            familyName:this.form.value['familyName'],
            countryCode:this.parent.collective.countryCode
        };
        
        if(this.parent.isForJunior && this.parent.collective.countryCode==='BI'){
            // the userId is split and the first element is appended (`- {a random UUID value}`)
            const generatedIdNumber = `${this.parent.policyholder.id.split('-')[0]}`+ '-' + `${this.shortuuid }`
            data.nationalId = generatedIdNumber;
          }
        
        if (this.parent.isForJunior)
        {
            var d = dayjs(data.dateOfBirth).utc(true).format();
            this.juniorSkipIprs()
            this.dependant =
            {
              idNumber: data.nationalId,
              name: data.firstName + ' ' + data.familyName,
              dateOfBirth: new Date(d),
              skipIprs: true,
              isJunior: true,
              isBeneficiary: false,
              skipPhone: true
            }


            this.parent.onBeneficiarySelect(this.parent.policyholder.id);


           this.submitDependants();
        }
        
        if (!this.parent.isForJunior)
        {
          const idData = {                
              idNumber: this.form.value.idNumber,          
              firstName: this.form.value.firstName,
              lastName: this.form.value.familyName,
              phone: this.form.value.phone,
          }
          const idCheck = await this.userRepository.checkBurundiIdentityAsync(idData);
           
        //   console.log(idCheck)
        const status = await idCheck.result.status;
         
          switch (idCheck.result.status)
        {
            case 1: // exists
                const currentPolicyholder: UserSelect = this.parent.policyholder;
                const userSelect: UserSelect =
                {
                    id: idCheck.result.details.id,
                    name: idCheck.result.details.name
                };

                // check spouse details differ from policyholder details
                if (currentPolicyholder.id === userSelect.id) 
                {
                    this.message = 'Spouse details can not be the same as policyholder';
                    break;
                }

                // check details does not have an active sale or active policy
                const response = await this.userRepository.getProfileShort(userSelect.id);
                
                if(!response.isSuccess) 
                {
                    // users registered by different user
                    // a trainer may not have acess to some previously registered members
                    this.message = 'User has already been registered';
                    break;
                }
                const profile = response.result;
                if (profile.hasPendingSales || profile.hasActivePolicies)
                {
                    this.message = 'hasActivePoliciesAndSales';
                    break;
                }

                this.dependant =
                {
                    name: idCheck.result.details.name,
                    idNumber: idCheck.result.details.idNumber,
                    userId: idCheck.result.details.id,
                    phone: this.form.value['phone'],
                    isJunior: false,
                    isBeneficiary: false,
                    skipIprs: true,
                    skipPhone: true
                }

                if (this.dependant.userId) this.parent.onBeneficiarySelect(this.dependant.userId);


                this.submitDependants();
                break;
            case 0: // NotFound
         
            const mydata = {  firstName:data.firstName,
            familyName:data.familyName,
            gender:0,
            nationalId:data.nationalId,
            phoneNumber:data.phoneNumber,
            haugeCustomerId:'',
            dateOfBirth:data.dateOfBirth,
            countryCode:this.parent.collective.countryCode,
            countryId:this.parent.collective.countryId
          }
              const result = await this.userRepository.createNewUser(mydata);

              if (result.isSuccess && result.data.userId)
              {        
                var dob = dayjs(data.dateOfBirth).utc(true).format();
                
                this.dependant =
                {
                  skipIprs: true,
                  idNumber: data.nationalId,
                  name: data.firstName + ' ' + data.familyName,
                  userId: result.data.userId,
                  phone: data.phoneNumber,
                  isJunior: false,
                  isBeneficiary: false,
                  skipPhone: true,
                  dateOfBirth: new Date(dob)
                }
    
                this.parent.onBeneficiarySelect(this.parent.policyholder.id);
    
                this.submitDependants();
    
                }

                this.message = result.message; 
              break;
          
        }

        this.clickedNext = false;  

        }
        
        
    }

    private submitDependants(): void
    {
        this.dependants.push(this.dependant);
        this.parent.onDependantsSubmit(this.dependants);
        this.parent.next();
    }

}
