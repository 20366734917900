import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface CollectivePayoutPendingModel
{
    collectiveId: string;
    collectiveName: string;
    organizationName: string;
    trainerName: string;
    phoneNumber: string;
    lastPayoutAt: Date;
    trainerPayout :number; 
}

export interface CollectivePayoutPaidModel
{
    collectiveId: string;
    destination: string;
    amount: number; 
    paidAt: Date;
}

export interface CollectivesPayoutQueryModel
{
    collectivesPayoutPendingModel: CollectivePayoutPendingModel[];
    collectivesPayoutPaidModel: CollectivePayoutPaidModel[];
}

@Injectable()
export class CollectivesPayoutRepository {

    constructor(private queryService: QueryService) { }

    public getPayouts(trainerId: string): Promise<IQueryResponse<CollectivesPayoutQueryModel>>
    {
        return this.queryService.queryAsync('CollectivesPayoutQuery',{ trainerId });
    }

}