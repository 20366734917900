import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    providers: [],
    declarations: [
        BackButtonComponent
    ],
    exports: [
        BackButtonComponent
    ]
})
export class BackButtonModule {}
