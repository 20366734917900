export { AuthRepository } from './auth.repository';
export { ClaimRepository } from './claim.repository';
export { CollectiveRepository } from './collective.repository';
export { CompanyRepository } from './company.repository';
export { CurrencyRepository } from './currency.repository';
export { DistributionChannelRepository } from './distribution-channel.repository';
export { DistributionPayoutRepository } from './distribution-payout.repository';
export { ElearningRepository } from './elearning.repository';
export { GroupRepository } from './group.repository';
export { MessageRepository } from './message.repository';
export { MetricRepository } from './metric.repository';
export { OrderRepository } from './order.repository';
export { OrganizationRepository } from './organization.repository';
export { PolicyRepository } from './policy.repository';
export { ProductRepository } from './product.repository';
export { RoleRepository } from './role.repository';
export { TeamRepository } from './team.repository';
export { TrainerRepository } from './trainer.repository';
export { TransactionRepository } from './transaction.repository';
export { UnderwriterRepository } from './underwriter.repository';
export { UserRepository } from './user.repository';
export { PaymentRepository } from './payment.repository';
export { HospitalRepository } from './hospital.repository';
export { IllnessRepository }  from './illness.repository';
export { GeolocationRepository } from './geolocation.repository';
