import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EditMemberComponent } from './components/edit-member.component';

@NgModule({
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    declarations: [
        EditMemberComponent
    ],
    exports: [
        EditMemberComponent
    ]
})
export class EditMemberModule
{
    
}

