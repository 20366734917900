import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CommandService } from "../providers/command.service";
import { TokenService } from "../providers/token.service";
import { DDF_SHARED_CONFIG, DDFSharedConfig } from "../ddfshared.config";

export interface SigninRequest
{
  username: string;
  password: string;
}

export interface RefreshRequest
{
  sessionId: string;
  password: string;
}

export interface SignupRequest
{
  country: 'KE' | '__',
  nationalId: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
}

export interface RequestPasswordRequest
{
  nationalId: string;
  phone: string;
  password: string;
}

@Injectable({ providedIn: 'any' })
export class AuthRepository
{
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService,
    private commandService: CommandService,
    @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig
  ) { }

  public signupAsync(data: SignupRequest): Promise<any>
  {
    return this.httpClient
      .post<any>(`${this.config.settings.authUrl}/signup`, data)
      .toPromise();
  }

  public signinAsync(data: SigninRequest): Promise<any>
  {
    const appId: string = this.config.settings.appId.toString();
    const headers = new HttpHeaders({
      AppId: appId,
    });

    return this.httpClient
      .post<any>(`${this.config.settings.authUrl}/signin`, data, {
        headers
      })
      .pipe(
        map((response) =>
        {
          this.tokenService.set(response.token);
          return response;
        })
      )
      .toPromise();
  }

  public refreshAsync(data: RefreshRequest): Promise<any>
  {
    console.log(41);
    
    const appId: string = this.config.settings.appId.toString();
    const headers = new HttpHeaders({
      AppId: appId,
    });

    return this.httpClient
      .post<any>(`${this.config.settings.authUrl}/refresh`, data, { headers })
      .pipe(
        map((response) =>
        {
          this.tokenService.set(response.token);
          return response;
        })
      )
      .toPromise();
  }

  public requestPasswordAsync(data: RequestPasswordRequest): Promise<any>
  {
    return this.commandService.publishAsync('UserUpdatePassword', data);
  }
}
