
export const DDF_SHARED_CONFIG = 'DDFSharedConfig';

export interface DDFSharedConfig
{
    settings: DDFSharedSettings,
}

export interface DDFSharedSettings
{
    production: boolean;
    appId: number;
    envName: string;
    appUrl: string;
    authUrl: string;
    cbiUrl: string;
    commandUrl: string;
    fileUrl: string;
    queryUrl: string;
    reportUrl: string;
    ssoUrl: string;
}
