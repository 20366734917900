import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BaseComponent } from "@ddfshared/abstracts";

export interface SearchUserDialogData
{
    title?: string;
    queryName?: string;
    excludedIds: string[];
    filter?: number;
    showCreateUser: boolean;
}

@Component({
    selector: 'search-user-dialog',
    templateUrl: './search-user.dialog.html'
})
export class SearchUserDialog extends BaseComponent
{
    public title?: string;
    public queryName: string | null = null;
    public excludedIds: string[] = [];
    public filter: number | null = null;
    public showCreateUser: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: SearchUserDialogData,
        private dialogRef: MatDialogRef<SearchUserDialog>
    )
    {
        super();

        if (data)
        {
            this.title = data.title;
            this.queryName = data.queryName;
            this.excludedIds = data.excludedIds;
            this.filter = data.filter;
            this.showCreateUser = data.showCreateUser;
        }
    }

    public selectExistingUser(user: any)
    {
        user = { ...user, userId: user.id };
        this.dialogRef.close(user);
    }

    public selectNewUser(user: any)
    {
        this.dialogRef.close(user);
    }
}
