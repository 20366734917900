import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@ddfshared/abstracts';
import { GeolocationRepository, ProvinceModel } from '@ddfshared/repositories/geolocation.repository';

@Component({
  selector: 'province',
  templateUrl: './province.component.html',
})
export class ProvinceComponent extends BaseComponent
{
  public provinceControl = new FormControl();
  public provinces: ProvinceModel[];

  @Input()
  public countryId?: string;

  @Output()
  public provinceEvent = new EventEmitter<ProvinceModel>();

  constructor(private geolocationRepository: GeolocationRepository) 
  {
    super();
  }

  public async ngOnInit(): Promise<void>
  {
    await this.loadAsync();
  }

  public async loadAsync(): Promise<void>
  {
    this.provinces = await this.getProvinces();
  }

  private async getProvinces(): Promise<ProvinceModel[]>
  {
    const response = await this.geolocationRepository.getProvinces(this.countryId);
    if (response.isSuccess && response.result)
    {
      return response.result;
    }
    return [];
  }

  public selectProvince(province: ProvinceModel): void
  {
    this.provinceEvent.emit(province);
  }
}
