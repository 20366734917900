import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../../../abstracts/base.component';

export interface Filter
{
    name: string;
    value: number;
}

export interface FilterDialogData
{
    filters: Filter[];
    title: string;
}


@Component({
    templateUrl: './filter.dialog.component.html',
})
export class FilterDialogComponent extends BaseComponent
{
    public form: UntypedFormGroup;
    public filters: Filter[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FilterDialogData,
        private dialogRef: MatDialogRef<FilterDialogComponent>,
    ) {
        super();

        this.form = new UntypedFormGroup({
            filter: new UntypedFormControl(null, [Validators.required])
        });

        this.filters = data.filters;

    }


    public async submit(ev: Event)
    {
        ev.preventDefault();

        if (!this.form.valid) return;

        const data = this.form.get('filter').value;

        this.dialogRef.close(data);
    }

}
