<div class="tw-flex tw-flex-row tw-max-w-[240px]">
    <div class="tw-flex-auto tw-uppercase">
        {{subtitle}}
    </div>
    <!-- <div
        <button type="button" mat-icon-button (click)="closeAsync()">
            <mat-icon class="tw-text-red-400" svgIcon="close"></mat-icon>
        </button>
    </div> -->
</div>

<mat-dialog-content>

    <ng-container *ngIf="currentStep == 1">
        <order-edit [parent]="this"></order-edit>
    </ng-container>

    <ng-container *ngIf="currentStep == 2">
        <order-product [parent]="this"></order-product>
    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 3">
        <order-dependant  *ngIf="collective.countryCode === 'KE'" [parent]="this"></order-dependant>
        <order-new-dependant *ngIf="collective.countryCode!== 'KE'" [parent]="this"></order-new-dependant>
    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 4">
        <order-confirm [parent]="this"></order-confirm>
    </ng-container>

    <ng-container *ngIf="orderType !== 1 && currentStep === 3">
        <order-confirm [parent]="this"></order-confirm>
    </ng-container>

</mat-dialog-content>