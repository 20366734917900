export interface Country
{
    name: string;
    phoneCode: string;
    alpha2Code: string;
    alpha3Code: string;
}

export interface County
{
    name: string;
    code: string;
    province?: string;
}

export class CountryHelper {

    public static getCounties(): County[]
    {
        return [
            {code: "KE-01", name: "Baringo"},
            {code: "KE-02", name: "Bomet"},
            {code: "KE-03", name: "Bungoma"},
            {code: "KE-04", name: "Busia"},
            {code: "KE-05", name: "Elgeyo/Marakwet"},
            {code: "KE-06", name: "Embu"},
            {code: "KE-07", name: "Garissa"},
            {code: "KE-08", name: "Homa Bay"},
            {code: "KE-09", name: "Isiolo"},
            {code: "KE-10", name: "Kajiado"},
            {code: "KE-11", name: "Kakamega"},
            {code: "KE-12", name: "Kericho"},
            {code: "KE-13", name: "Kiambu"},
            {code: "KE-14", name: "Kilifi"},
            {code: "KE-15", name: "Kirinyaga"},
            {code: "KE-16", name: "Kisii"},
            {code: "KE-17", name: "Kisumu"},
            {code: "KE-18", name: "Kitui"},
            {code: "KE-19", name: "Kwale"},
            {code: "KE-20", name: "Laikipia"},
            {code: "KE-21", name: "Lamu"},
            {code: "KE-22", name: "Machakos"},
            {code: "KE-23", name: "Makueni"},
            {code: "KE-24", name: "Mandera"},
            {code: "KE-25", name: "Marsabit"},
            {code: "KE-26", name: "Meru"},
            {code: "KE-27", name: "Migori"},
            {code: "KE-28", name: "Mombasa"},
            {code: "KE-29", name: "Murang'a"},
            {code: "KE-30", name: "Nairobi City"},
            {code: "KE-31", name: "Nakuru"},
            {code: "KE-32", name: "Nandi"},
            {code: "KE-33", name: "Narok"},
            {code: "KE-34", name: "Nyamira"},
            {code: "KE-35", name: "Nyandarua"},
            {code: "KE-36", name: "Nyeri"},
            {code: "KE-37", name: "Samburu"},
            {code: "KE-38", name: "Siaya"},
            {code: "KE-39", name: "Taita/Taveta"},
            {code: "KE-40", name: "Tana River"},
            {code: "KE-41", name: "Tharaka-Nithi"},
            {code: "KE-42", name: "Trans Nzoia"},
            {code: "KE-43", name: "Turkana"},
            {code: "KE-44", name: "Uasin Gishu"},
            {code: "KE-45", name: "Vihiga"},
            {code: "KE-46", name: "Wajir"},
            {code: "KE-47", name: "West Pokot"},
        ];
    }

    public static getCountries(): Country[]
    {
        return [
            {
                "name": "Afghanistan",
                "phoneCode": "+93",
                "alpha2Code": "AF",
                "alpha3Code": "AFG"
            },
            {
                "name": "Albania",
                "phoneCode": "+355",
                "alpha2Code": "AL",
                "alpha3Code": "ALB"
            },
            {
                "name": "Algeria",
                "phoneCode": "+213",
                "alpha2Code": "DZ",
                "alpha3Code": "DZA"
            },
            {
                "name": "American Samoa",
                "phoneCode": "+1",
                "alpha2Code": "AS",
                "alpha3Code": "ASM"
            },
            {
                "name": "Andorra",
                "phoneCode": "+376",
                "alpha2Code": "AD",
                "alpha3Code": "AND"
            },
            {
                "name": "Angola",
                "phoneCode": "+244",
                "alpha2Code": "AO",
                "alpha3Code": "AGO"
            },
            {
                "name": "Anguilla",
                "phoneCode": "+1",
                "alpha2Code": "AI",
                "alpha3Code": "AIA"
            },
            {
                "name": "Antigua and Barbuda",
                "phoneCode": "+1",
                "alpha2Code": "AG",
                "alpha3Code": "ATG"
            },
            {
                "name": "Argentina",
                "phoneCode": "+54",
                "alpha2Code": "AR",
                "alpha3Code": "ARG"
            },
            {
                "name": "Armenia",
                "phoneCode": "+374",
                "alpha2Code": "AM",
                "alpha3Code": "ARM"
            },
            {
                "name": "Aruba",
                "phoneCode": "+297",
                "alpha2Code": "AW",
                "alpha3Code": "ABW"
            },
            {
                "name": "Australia",
                "phoneCode": "+61",
                "alpha2Code": "AU",
                "alpha3Code": "AUS"
            },
            {
                "name": "Austria",
                "phoneCode": "+43",
                "alpha2Code": "AI",
                "alpha3Code": "AUT"
            },
            {
                "name": "Azerbaijan",
                "phoneCode": "+994",
                "alpha2Code": "AZ",
                "alpha3Code": "AZE"
            },
            {
                "name": "Bahrain",
                "phoneCode": "+973",
                "alpha2Code": "BH",
                "alpha3Code": "BHR"
            },
            {
                "name": "Bangladesh",
                "phoneCode": "+880",
                "alpha2Code": "BD",
                "alpha3Code": "BGD"
            },
            {
                "name": "Barbados",
                "phoneCode": "+1",
                "alpha2Code": "BB",
                "alpha3Code": "BRB"
            },
            {
                "name": "Belarus",
                "phoneCode": "+375",
                "alpha2Code": "BY",
                "alpha3Code": "BLR"
            },
            {
                "name": "Belgium",
                "phoneCode": "+32",
                "alpha2Code": "BE",
                "alpha3Code": "BEL"
            },
            {
                "name": "Belize",
                "phoneCode": "+501",
                "alpha2Code": "BZ",
                "alpha3Code": "BLZ"
            },
            {
                "name": "Benin",
                "phoneCode": "+229",
                "alpha2Code": "BJ",
                "alpha3Code": "BEN"
            },
            {
                "name": "Bermuda",
                "phoneCode": "+1",
                "alpha2Code": "BM",
                "alpha3Code": "BMU"
            },
            {
                "name": "Bhutan",
                "phoneCode": "+975",
                "alpha2Code": "BT",
                "alpha3Code": "BTN"
            },
            {
                "name": "Bolivia",
                "phoneCode": "+591",
                "alpha2Code": "BO",
                "alpha3Code": "BOL"
            },
            {
                "name": "Bonaire, Sint Eustatius and Saba",
                "phoneCode": "+599",
                "alpha2Code": "BQ",
                "alpha3Code": "BES"
            },
            {
                "name": "Bosnia and Herzegovina",
                "phoneCode": "+387",
                "alpha2Code": "BA",
                "alpha3Code": "BIH"
            },
            {
                "name": "Botswana",
                "phoneCode": "+267",
                "alpha2Code": "BW",
                "alpha3Code": "BWA"
            },
            {
                "name": "Brazil",
                "phoneCode": "+55",
                "alpha2Code": "BR",
                "alpha3Code": "BRA"
            },
            {
                "name": "British Indian Ocean Territory",
                "phoneCode": "+246",
                "alpha2Code": "IO",
                "alpha3Code": "IOT"
            },
            {
                "name": "British Virgin Islands",
                "phoneCode": "+1",
                "alpha2Code": "VG",
                "alpha3Code": "VGB"
            },
            {
                "name": "Brunei Darussalam",
                "phoneCode": "+673",
                "alpha2Code": "BN",
                "alpha3Code": "BRN"
            },
            {
                "name": "Bulgaria",
                "phoneCode": "+359",
                "alpha2Code": "BG",
                "alpha3Code": "BGR"
            },
            {
                "name": "Burkina Faso",
                "phoneCode": "+226",
                "alpha2Code": "BF",
                "alpha3Code": "BFA"
            },
            {
                "name": "Myanmar",
                "phoneCode": "+95",
                "alpha2Code": "MM",
                "alpha3Code": "MMR"
            },
            {
                "name": "Burundi",
                "phoneCode": "+257",
                "alpha2Code": "BI",
                "alpha3Code": "BDI"
            },
            {
                "name": "Cambodia",
                "phoneCode": "+855",
                "alpha2Code": "KH",
                "alpha3Code": "KHM"
            },
            {
                "name": "Cameroon",
                "phoneCode": "+237",
                "alpha2Code": "CM",
                "alpha3Code": "CMR"
            },
            {
                "name": "Canada",
                "phoneCode": "+1",
                "alpha2Code": "CA",
                "alpha3Code": "CAN"
            },
            {
                "name": "Cabo Verde",
                "phoneCode": "+238",
                "alpha2Code": "CV",
                "alpha3Code": "CPV"
            },
            {
                "name": "Cayman Islands",
                "phoneCode": "+1",
                "alpha2Code": "KY",
                "alpha3Code": "CYM"
            },
            {
                "name": "Central African Republic",
                "phoneCode": "+236",
                "alpha2Code": "CF",
                "alpha3Code": "CAF"
            },
            {
                "name": "Chad",
                "phoneCode": "+235",
                "alpha2Code": "ID",
                "alpha3Code": "TCD"
            },
            {
                "name": "Chile",
                "phoneCode": "+56",
                "alpha2Code": "CL",
                "alpha3Code": "CHL"
            },
            {
                "name": "China",
                "phoneCode": "+86",
                "alpha2Code": "CN",
                "alpha3Code": "CHN"
            },
            {
                "name": "Colombia",
                "phoneCode": "+57",
                "alpha2Code": "CO",
                "alpha3Code": "COL"
            },
            {
                "name": "Comoros",
                "phoneCode": "+269",
                "alpha2Code": "KM",
                "alpha3Code": "COM"
            },
            {
                "name": "Cook Islands",
                "phoneCode": "+682",
                "alpha2Code": "CK",
                "alpha3Code": "COK"
            },
            {
                "name": "Costa Rica",
                "phoneCode": "+506",
                "alpha2Code": "CR",
                "alpha3Code": "CRI"
            },
            {
                "name": "Côte dIvoire",
                "phoneCode": "+225",
                "alpha2Code": "CI",
                "alpha3Code": "CIV"
            },
            {
                "name": "Croatia",
                "phoneCode": "+385",
                "alpha2Code": "HR",
                "alpha3Code": "HRV"
            },
            {
                "name": "Cuba",
                "phoneCode": "+53",
                "alpha2Code": "CU",
                "alpha3Code": "CUB"
            },
            {
                "name": "Curaçao",
                "phoneCode": "+599",
                "alpha2Code": "CW",
                "alpha3Code": "CUW"
            },
            {
                "name": "Cyprus",
                "phoneCode": "+357",
                "alpha2Code": "CY",
                "alpha3Code": "CYP"
            },
            {
                "name": "Czechia",
                "phoneCode": "+420",
                "alpha2Code": "CZ",
                "alpha3Code": "CZE"
            },
            {
                "name": "Denmark",
                "phoneCode": "+45",
                "alpha2Code": "DK",
                "alpha3Code": "DNK"
            },
            {
                "name": "Djibouti",
                "phoneCode": "+253",
                "alpha2Code": "DJ",
                "alpha3Code": "DJI"
            },
            {
                "name": "Dominica",
                "phoneCode": "+1",
                "alpha2Code": "DM",
                "alpha3Code": "DMA"
            },
            {
                "name": "Dominican Republic",
                "phoneCode": "+1",
                "alpha2Code": "DO",
                "alpha3Code": "DOM"
            },
            {
                "name": "Ecuador",
                "phoneCode": "+593",
                "alpha2Code": "EC",
                "alpha3Code": "ECU"
            },
            {
                "name": "Egypt",
                "phoneCode": "+20",
                "alpha2Code": "EG",
                "alpha3Code": "EGY"
            },
            {
                "name": "El Salvador",
                "phoneCode": "+503",
                "alpha2Code": "SV",
                "alpha3Code": "SLV"
            },
            {
                "name": "Equatorial Guinea",
                "phoneCode": "+240",
                "alpha2Code": "GQ",
                "alpha3Code": "GNQ"
            },
            {
                "name": "Eritrea",
                "phoneCode": "+291",
                "alpha2Code": "ER",
                "alpha3Code": "ERI"
            },
            {
                "name": "Estonia",
                "phoneCode": "+372",
                "alpha2Code": "EE",
                "alpha3Code": "EST"
            },
            {
                "name": "Ethiopia",
                "phoneCode": "+251",
                "alpha2Code": "ET",
                "alpha3Code": "ETH"
            },
            {
                "name": "Falkland Islands",
                "phoneCode": "+500",
                "alpha2Code": "FK",
                "alpha3Code": "FLK"
            },
            {
                "name": "Faroe Islands",
                "phoneCode": "+298",
                "alpha2Code": "FO",
                "alpha3Code": "FRO"
            },
            {
                "name": "Federated States of Micronesia",
                "phoneCode": "+691",
                "alpha2Code": "FM",
                "alpha3Code": "FSM"
            },
            {
                "name": "Fiji",
                "phoneCode": "+679",
                "alpha2Code": "FJ",
                "alpha3Code": "FJI"
            },
            {
                "name": "Finland",
                "phoneCode": "+358",
                "alpha2Code": "FI",
                "alpha3Code": "FIN"
            },
            {
                "name": "France",
                "phoneCode": "+33",
                "alpha2Code": "FR",
                "alpha3Code": "FRA"
            },
            {
                "name": "French Guiana",
                "phoneCode": "+594",
                "alpha2Code": "GF",
                "alpha3Code": "GUF"
            },
            {
                "name": "French Polynesia",
                "phoneCode": "+689",
                "alpha2Code": "PF",
                "alpha3Code": "PYF"
            },
            {
                "name": "Gabon",
                "phoneCode": "+241",
                "alpha2Code": "GA",
                "alpha3Code": "GAB"
            },
            {
                "name": "Georgia",
                "phoneCode": "+995",
                "alpha2Code": "GE",
                "alpha3Code": "GEO"
            },
            {
                "name": "Germany",
                "phoneCode": "+49",
                "alpha2Code": "DE",
                "alpha3Code": "DEU"
            },
            {
                "name": "Ghana",
                "phoneCode": "+233",
                "alpha2Code": "GH",
                "alpha3Code": "GHA"
            },
            {
                "name": "Gibraltar",
                "phoneCode": "+350",
                "alpha2Code": "GI",
                "alpha3Code": "GIB"
            },
            {
                "name": "Greece",
                "phoneCode": "+30",
                "alpha2Code": "GR",
                "alpha3Code": "GRC"
            },
            {
                "name": "Greenland",
                "phoneCode": "+299",
                "alpha2Code": "GL",
                "alpha3Code": "GRL"
            },
            {
                "name": "Grenada",
                "phoneCode": "+1",
                "alpha2Code": "GD",
                "alpha3Code": "GRD"
            },
            {
                "name": "Guadeloupe",
                "phoneCode": "+590",
                "alpha2Code": "GP",
                "alpha3Code": "GLP"
            },
            {
                "name": "Guam",
                "phoneCode": "+1",
                "alpha2Code": "GU",
                "alpha3Code": "GUM"
            },
            {
                "name": "Guatemala",
                "phoneCode": "+502",
                "alpha2Code": "GT",
                "alpha3Code": "GTM"
            },
            {
                "name": "Guinea",
                "phoneCode": "+224",
                "alpha2Code": "GN",
                "alpha3Code": "GIN"
            },
            {
                "name": "Guinea-Bissau",
                "phoneCode": "+245",
                "alpha2Code": "GW",
                "alpha3Code": "GNB"
            },
            {
                "name": "Guyana",
                "phoneCode": "+592",
                "alpha2Code": "GY",
                "alpha3Code": "GUY"
            },
            {
                "name": "Haiti",
                "phoneCode": "+509",
                "alpha2Code": "HT",
                "alpha3Code": "HTI"
            },
            {
                "name": "Honduras",
                "phoneCode": "+504",
                "alpha2Code": "HN",
                "alpha3Code": "HND"
            },
            {
                "name": "Hong Kong",
                "phoneCode": "+852",
                "alpha2Code": "HK",
                "alpha3Code": "HKG"
            },
            {
                "name": "Hungary",
                "phoneCode": "+36",
                "alpha2Code": "HU",
                "alpha3Code": "HUN"
            },
            {
                "name": "Iceland",
                "phoneCode": "+354",
                "alpha2Code": "IS",
                "alpha3Code": "ISL"
            },
            {
                "name": "India",
                "phoneCode": "+91",
                "alpha2Code": "IN",
                "alpha3Code": "IND"
            },
            {
                "name": "Indonesia",
                "phoneCode": "+62",
                "alpha2Code": "ID",
                "alpha3Code": "IDN"
            },
            {
                "name": "Iran",
                "phoneCode": "+98",
                "alpha2Code": "IR",
                "alpha3Code": "IRN"
            },
            {
                "name": "Iraq",
                "phoneCode": "+964",
                "alpha2Code": "IQ",
                "alpha3Code": "IRQ"
            },
            {
                "name": "Ireland",
                "phoneCode": "+353",
                "alpha2Code": "IE",
                "alpha3Code": "IRL"
            },
            {
                "name": "Israel",
                "phoneCode": "+972",
                "alpha2Code": "IL",
                "alpha3Code": "ISR"
            },
            {
                "name": "Italy",
                "phoneCode": "+39",
                "alpha2Code": "IT",
                "alpha3Code": "ITA"
            },
            {
                "name": "Jamaica",
                "phoneCode": "+1",
                "alpha2Code": "JM",
                "alpha3Code": "JAM"
            },
            {
                "name": "Japan",
                "phoneCode": "+81",
                "alpha2Code": "JP",
                "alpha3Code": "JPN"
            },
            {
                "name": "Jordan",
                "phoneCode": "+962",
                "alpha2Code": "JO",
                "alpha3Code": "JOR"
            },
            {
                "name": "Kazakhstan",
                "phoneCode": "+7",
                "alpha2Code": "KZ",
                "alpha3Code": "KAZ"
            },
            {
                "name": "Kenya",
                "phoneCode": "+254",
                "alpha2Code": "KE",
                "alpha3Code": "KEN"
            },
            {
                "name": "Kiribati",
                "phoneCode": "+686",
                "alpha2Code": "KI",
                "alpha3Code": "KIR"
            },
            {
                "name": "Kuwait",
                "phoneCode": "+965",
                "alpha2Code": "KW",
                "alpha3Code": "KWT"
            },
            {
                "name": "Kyrgyzstan",
                "phoneCode": "+996",
                "alpha2Code": "KG",
                "alpha3Code": "KGZ"
            },
            {
                "name": "Laos",
                "phoneCode": "+856",
                "alpha2Code": "LA",
                "alpha3Code": "LAO"
            },
            {
                "name": "Latvia",
                "phoneCode": "+371",
                "alpha2Code": "LV",
                "alpha3Code": "LVA"
            },
            {
                "name": "Lebanon",
                "phoneCode": "+961",
                "alpha2Code": "LB",
                "alpha3Code": "LBN"
            },
            {
                "name": "Lesotho",
                "phoneCode": "+266",
                "alpha2Code": "LS",
                "alpha3Code": "LSO"
            },
            {
                "name": "Liberia",
                "phoneCode": "+231",
                "alpha2Code": "LR",
                "alpha3Code": "LBR"
            },
            {
                "name": "Libya",
                "phoneCode": "+218",
                "alpha2Code": "LY",
                "alpha3Code": "LBY"
            },
            {
                "name": "Liechtenstein",
                "phoneCode": "+423",
                "alpha2Code": "LI",
                "alpha3Code": "LIE"
            },
            {
                "name": "Lithuania",
                "phoneCode": "+370",
                "alpha2Code": "LT",
                "alpha3Code": "LTU"
            },
            {
                "name": "Luxembourg",
                "phoneCode": "+352",
                "alpha2Code": "LU",
                "alpha3Code": "LUX"
            },
            {
                "name": "Macao",
                "phoneCode": "+853",
                "alpha2Code": "MO",
                "alpha3Code": "MAC"
            },
            {
                "name": "Macedonia",
                "phoneCode": "+389",
                "alpha2Code": "MK",
                "alpha3Code": "MKD"
            },
            {
                "name": "Madagascar",
                "phoneCode": "+261",
                "alpha2Code": "MG",
                "alpha3Code": "MDG"
            },
            {
                "name": "Malawi",
                "phoneCode": "+265",
                "alpha2Code": "MW",
                "alpha3Code": "MWI"
            },
            {
                "name": "Malaysia",
                "phoneCode": "+60",
                "alpha2Code": "MY",
                "alpha3Code": "MYS"
            },
            {
                "name": "Maldives",
                "phoneCode": "+960",
                "alpha2Code": "MV",
                "alpha3Code": "MDV"
            },
            {
                "name": "Mali",
                "phoneCode": "+223",
                "alpha2Code": "ML",
                "alpha3Code": "MLI"
            },
            {
                "name": "Malta",
                "phoneCode": "+356",
                "alpha2Code": "MT",
                "alpha3Code": "MLT"
            },
            {
                "name": "Marshall Islands",
                "phoneCode": "+692",
                "alpha2Code": "MH",
                "alpha3Code": "MHL"
            },
            {
                "name": "Martinique",
                "phoneCode": "+596",
                "alpha2Code": "MQ",
                "alpha3Code": "MTQ"
            },
            {
                "name": "Mauritania",
                "phoneCode": "+222",
                "alpha2Code": "MR",
                "alpha3Code": "MRT"
            },
            {
                "name": "Mauritius",
                "phoneCode": "+230",
                "alpha2Code": "MU",
                "alpha3Code": "MUS"
            },
            {
                "name": "Mayotte",
                "phoneCode": "+262",
                "alpha2Code": "YT",
                "alpha3Code": "MYT"
            },
            {
                "name": "Mexico",
                "phoneCode": "+52",
                "alpha2Code": "MX",
                "alpha3Code": "MEX"
            },
            {
                "name": "Moldova",
                "phoneCode": "+373",
                "alpha2Code": "MD",
                "alpha3Code": "MDA"
            },
            {
                "name": "Monaco",
                "phoneCode": "+377",
                "alpha2Code": "MC",
                "alpha3Code": "MCO"
            },
            {
                "name": "Mongolia",
                "phoneCode": "+976",
                "alpha2Code": "MN",
                "alpha3Code": "MNG"
            },
            {
                "name": "Montenegro",
                "phoneCode": "+382",
                "alpha2Code": "ME",
                "alpha3Code": "MNE"
            },
            {
                "name": "Montserrat",
                "phoneCode": "+1",
                "alpha2Code": "MS",
                "alpha3Code": "MSR"
            },
            {
                "name": "Morocco",
                "phoneCode": "+212",
                "alpha2Code": "MA",
                "alpha3Code": "MAR"
            },
            {
                "name": "Mozambique",
                "phoneCode": "+258",
                "alpha2Code": "MZ",
                "alpha3Code": "MOZ"
            },
            {
                "name": "Namibia",
                "phoneCode": "+264",
                "alpha2Code": "NA",
                "alpha3Code": "NAM"
            },
            {
                "name": "Nauru",
                "phoneCode": "+674",
                "alpha2Code": "NR",
                "alpha3Code": "NRU"
            },
            {
                "name": "Nepal",
                "phoneCode": "+977",
                "alpha2Code": "NP",
                "alpha3Code": "NPL"
            },
            {
                "name": "Netherlands",
                "phoneCode": "+31",
                "alpha2Code": "NL",
                "alpha3Code": "NLD"
            },
            {
                "name": "Netherlands Antilles",
                "phoneCode": "+599",
                "alpha2Code": "AN",
                "alpha3Code": "ANT"
            },
            {
                "name": "New Caledonia",
                "phoneCode": "+687",
                "alpha2Code": "NC",
                "alpha3Code": "NCL"
            },
            {
                "name": "New Zealand",
                "phoneCode": "+64",
                "alpha2Code": "NZ",
                "alpha3Code": "NZL"
            },
            {
                "name": "Nicaragua",
                "phoneCode": "+505",
                "alpha2Code": "NI",
                "alpha3Code": "NIC"
            },
            {
                "name": "Niger",
                "phoneCode": "+227",
                "alpha2Code": "NE",
                "alpha3Code": "NER"
            },
            {
                "name": "Nigeria",
                "phoneCode": "+234",
                "alpha2Code": "NG",
                "alpha3Code": "NGA"
            },
            {
                "name": "Niue",
                "phoneCode": "+683",
                "alpha2Code": "NU",
                "alpha3Code": "NIU"
            },
            {
                "name": "Norfolk Island",
                "phoneCode": "+672",
                "alpha2Code": "NF",
                "alpha3Code": "NFK"
            },
            {
                "name": "North Korea",
                "phoneCode": "+850",
                "alpha2Code": "KP",
                "alpha3Code": "PRK"
            },
            {
                "name": "Northern Mariana Islands",
                "phoneCode": "+1",
                "alpha2Code": "MP",
                "alpha3Code": "MNP"
            },
            {
                "name": "Norway",
                "phoneCode": "+47",
                "alpha2Code": "NO",
                "alpha3Code": "NOR"
            },
            {
                "name": "Oman",
                "phoneCode": "+968",
                "alpha2Code": "OM",
                "alpha3Code": "OMN"
            },
            {
                "name": "Pakistan",
                "phoneCode": "+92",
                "alpha2Code": "PK",
                "alpha3Code": "PAK"
            },
            {
                "name": "Palau",
                "phoneCode": "+680",
                "alpha2Code": "PW",
                "alpha3Code": "PLW"
            },
            {
                "name": "Palestine",
                "phoneCode": "+970",
                "alpha2Code": "PS",
                "alpha3Code": "PSE"
            },
            {
                "name": "Panama",
                "phoneCode": "+507",
                "alpha2Code": "PA",
                "alpha3Code": "PAN"
            },
            {
                "name": "Papua New Guinea",
                "phoneCode": "+675",
                "alpha2Code": "PG",
                "alpha3Code": "PNG"
            },
            {
                "name": "Paraguay",
                "phoneCode": "+595",
                "alpha2Code": "PY",
                "alpha3Code": "PRY"
            },
            {
                "name": "Peru",
                "phoneCode": "+51",
                "alpha2Code": "PE",
                "alpha3Code": "PER"
            },
            {
                "name": "Philippines",
                "phoneCode": "+63",
                "alpha2Code": "PH",
                "alpha3Code": "PHL"
            },
            {
                "name": "Poland",
                "phoneCode": "+48",
                "alpha2Code": "PL",
                "alpha3Code": "POL"
            },
            {
                "name": "Portugal",
                "phoneCode": "+351",
                "alpha2Code": "PT",
                "alpha3Code": "PRT"
            },
            {
                "name": "Puerto Rico",
                "phoneCode": "+1",
                "alpha2Code": "PR",
                "alpha3Code": "PRI"
            },
            {
                "name": "Qatar",
                "phoneCode": "+974",
                "alpha2Code": "QA",
                "alpha3Code": "QAT"
            },
            {
                "name": "Congo",
                "phoneCode": "+242",
                "alpha2Code": "CG",
                "alpha3Code": "COG"
            },
            {
                "name": "Réunion",
                "phoneCode": "+262",
                "alpha2Code": "RE",
                "alpha3Code": "REU"
            },
            {
                "name": "Romania",
                "phoneCode": "+40",
                "alpha2Code": "RO",
                "alpha3Code": "ROU"
            },
            {
                "name": "Russia",
                "phoneCode": "+7",
                "alpha2Code": "RU",
                "alpha3Code": "RUS"
            },
            {
                "name": "Rwanda",
                "phoneCode": "+250",
                "alpha2Code": "RW",
                "alpha3Code": "RWA"
            },
            {
                "name": "Saint Barthélemy",
                "phoneCode": "+590",
                "alpha2Code": "BL",
                "alpha3Code": "BLM"
            },
            {
                "name": "Saint Helena",
                "phoneCode": "+290",
                "alpha2Code": "SH",
                "alpha3Code": "SHN"
            },
            {
                "name": "Saint Kitts and Nevis",
                "phoneCode": "+1",
                "alpha2Code": "KN",
                "alpha3Code": "KNA"
            },
            {
                "name": "Saint Martin",
                "phoneCode": "+590",
                "alpha2Code": "MF",
                "alpha3Code": "MAF"
            },
            {
                "name": "Saint Pierre and Miquelon",
                "phoneCode": "+508",
                "alpha2Code": "PM",
                "alpha3Code": "SPM"
            },
            {
                "name": "Saint Vincent and the Grenadines",
                "phoneCode": "+1",
                "alpha2Code": "VC",
                "alpha3Code": "VCT"
            },
            {
                "name": "Samoa",
                "phoneCode": "+685",
                "alpha2Code": "WS",
                "alpha3Code": "WSM"
            },
            {
                "name": "San Marino",
                "phoneCode": "+378",
                "alpha2Code": "SM",
                "alpha3Code": "SMR"
            },
            {
                "name": "São Tomé and Príncipe",
                "phoneCode": "+239",
                "alpha2Code": "ST",
                "alpha3Code": "STP"
            },
            {
                "name": "Saudi Arabia",
                "phoneCode": "+966",
                "alpha2Code": "SA",
                "alpha3Code": "SAU"
            },
            {
                "name": "Senegal",
                "phoneCode": "+221",
                "alpha2Code": "SN",
                "alpha3Code": "SEN"
            },
            {
                "name": "Serbia",
                "phoneCode": "+381",
                "alpha2Code": "RS",
                "alpha3Code": "SRB"
            },
            {
                "name": "Seychelles",
                "phoneCode": "+248",
                "alpha2Code": "SC",
                "alpha3Code": "SYC"
            },
            {
                "name": "Sierra Leone",
                "phoneCode": "+232",
                "alpha2Code": "SL",
                "alpha3Code": "SLE"
            },
            {
                "name": "Singapore",
                "phoneCode": "+65",
                "alpha2Code": "SG",
                "alpha3Code": "SGP"
            },
            {
                "name": "Sint Maarten (Dutch part)",
                "phoneCode": "+1",
                "alpha2Code": "SX",
                "alpha3Code": "SXM"
            },
            {
                "name": "Slovakia",
                "phoneCode": "+421",
                "alpha2Code": "SK",
                "alpha3Code": "SVK"
            },
            {
                "name": "Slovenia",
                "phoneCode": "+386",
                "alpha2Code": "SI",
                "alpha3Code": "SVN"
            },
            {
                "name": "Solomon Islands",
                "phoneCode": "+677",
                "alpha2Code": "SB",
                "alpha3Code": "SLB"
            },
            {
                "name": "Somalia",
                "phoneCode": "+252",
                "alpha2Code": "SO",
                "alpha3Code": "SOM"
            },
            {
                "name": "South Africa",
                "phoneCode": "+27",
                "alpha2Code": "ZA",
                "alpha3Code": "ZAF"
            },
            {
                "name": "South Korea",
                "phoneCode": "+82",
                "alpha2Code": "KR",
                "alpha3Code": "KOR"
            },
            {
                "name": "South Sudan",
                "phoneCode": "+211",
                "alpha2Code": "SS",
                "alpha3Code": "SSD"
            },
            {
                "name": "Spain",
                "phoneCode": "+34",
                "alpha2Code": "ES",
                "alpha3Code": "ESP"
            },
            {
                "name": "Sri Lanka",
                "phoneCode": "+94",
                "alpha2Code": "LK",
                "alpha3Code": "LKA"
            },
            {
                "name": "Saint Lucia",
                "phoneCode": "+1",
                "alpha2Code": "LC",
                "alpha3Code": "LCA"
            },
            {
                "name": "Sudan",
                "phoneCode": "+249",
                "alpha2Code": "SD",
                "alpha3Code": "SDN"
            },
            {
                "name": "Suriname",
                "phoneCode": "+597",
                "alpha2Code": "SR",
                "alpha3Code": "SUR"
            },
            {
                "name": "Swaziland",
                "phoneCode": "+268",
                "alpha2Code": "SZ",
                "alpha3Code": "SWZ"
            },
            {
                "name": "Sweden",
                "phoneCode": "+46",
                "alpha2Code": "SE",
                "alpha3Code": "SWE"
            },
            {
                "name": "Switzerland",
                "phoneCode": "+41",
                "alpha2Code": "CH",
                "alpha3Code": "CHE"
            },
            {
                "name": "Syrian Arab Republic",
                "phoneCode": "+963",
                "alpha2Code": "SY",
                "alpha3Code": "SYR"
            },
            {
                "name": "Taiwan",
                "phoneCode": "+886",
                "alpha2Code": "TW",
                "alpha3Code": "TWN"
            },
            {
                "name": "Tajikistan",
                "phoneCode": "+992",
                "alpha2Code": "TJ",
                "alpha3Code": "TJK"
            },
            {
                "name": "Tanzania",
                "phoneCode": "+255",
                "alpha2Code": "TZ",
                "alpha3Code": "TZA"
            },
            {
                "name": "Thailand",
                "phoneCode": "+66",
                "alpha2Code": "TH",
                "alpha3Code": "THA"
            },
            {
                "name": "Bahamas",
                "phoneCode": "+1",
                "alpha2Code": "BS",
                "alpha3Code": "BHS"
            },
            {
                "name": "Gambia",
                "phoneCode": "+220",
                "alpha2Code": "GM",
                "alpha3Code": "GMB"
            },
            {
                "name": "Timor-Leste",
                "phoneCode": "+670",
                "alpha2Code": "TL",
                "alpha3Code": "TLS"
            },
            {
                "name": "Togo",
                "phoneCode": "+228",
                "alpha2Code": "TG",
                "alpha3Code": "TGO"
            },
            {
                "name": "Tokelau",
                "phoneCode": "+690",
                "alpha2Code": "TK",
                "alpha3Code": "TKL"
            },
            {
                "name": "Tonga",
                "phoneCode": "+676",
                "alpha2Code": "TO",
                "alpha3Code": "TON"
            },
            {
                "name": "Trinidad and Tobago",
                "phoneCode": "+1",
                "alpha2Code": "TT",
                "alpha3Code": "TTO"
            },
            {
                "name": "Tunisia",
                "phoneCode": "+216",
                "alpha2Code": "TN",
                "alpha3Code": "TUN"
            },
            {
                "name": "Turkey",
                "phoneCode": "+90",
                "alpha2Code": "TR",
                "alpha3Code": "TUR"
            },
            {
                "name": "Turkmenistan",
                "phoneCode": "+993",
                "alpha2Code": "TM",
                "alpha3Code": "TKM"
            },
            {
                "name": "Turks and Caicos Islands",
                "phoneCode": "+1",
                "alpha2Code": "TC",
                "alpha3Code": "TCA"
            },
            {
                "name": "Tuvalu",
                "phoneCode": "+688",
                "alpha2Code": "TV",
                "alpha3Code": "TUV"
            },
            {
                "name": "Uganda",
                "phoneCode": "+256",
                "alpha2Code": "UG",
                "alpha3Code": "UGA"
            },
            {
                "name": "Ukraine",
                "phoneCode": "+380",
                "alpha2Code": "UA",
                "alpha3Code": "UKR"
            },
            {
                "name": "United Arab Emirates",
                "phoneCode": "+971",
                "alpha2Code": "AE",
                "alpha3Code": "ARE"
            },
            {
                "name": "United Kingdom",
                "phoneCode": "+44",
                "alpha2Code": "GB",
                "alpha3Code": "GBR"
            },
            {
                "name": "United States",
                "phoneCode": "+1",
                "alpha2Code": "US",
                "alpha3Code": "USA"
            },
            {
                "name": "Uruguay",
                "phoneCode": "+598",
                "alpha2Code": "UY",
                "alpha3Code": "URY"
            },
            {
                "name": "U.S. Virgin Islands",
                "phoneCode": "+1",
                "alpha2Code": "VI",
                "alpha3Code": "VIR"
            },
            {
                "name": "Uzbekistan",
                "phoneCode": "+998",
                "alpha2Code": "UZ",
                "alpha3Code": "UZB"
            },
            {
                "name": "Vanuatu",
                "phoneCode": "+678",
                "alpha2Code": "VU",
                "alpha3Code": "VUT"
            },
            {
                "name": "Vatican City",
                "phoneCode": "+39",
                "alpha2Code": "VA",
                "alpha3Code": "VAT"
            },
            {
                "name": "Venezuela",
                "phoneCode": "+58",
                "alpha2Code": "VE",
                "alpha3Code": "VEN"
            },
            {
                "name": "Vietnam",
                "phoneCode": "+84",
                "alpha2Code": "VN",
                "alpha3Code": "VNM"
            },
            {
                "name": "Wallis and Futuna",
                "phoneCode": "+681",
                "alpha2Code": "WF",
                "alpha3Code": "WLF"
            },
            {
                "name": "Yemen",
                "phoneCode": "+967",
                "alpha2Code": "YE",
                "alpha3Code": "YEM"
            },
            {
                "name": "Zambia",
                "phoneCode": "+260",
                "alpha2Code": "ZM",
                "alpha3Code": "ZMB"
            },
            {
                "name": "Zimbabwe",
                "phoneCode": "+263",
                "alpha2Code": "ZW",
                "alpha3Code": "ZWE"
            }
        ];
    }
}
