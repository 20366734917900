import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy
{
    private _ngSubscribe$ = new Subject<void>();

    protected get ngSubscribe$(): Observable<void>
    {
        return this._ngSubscribe$.asObservable();
    }

    public ngOnInit(): void
    {
    }

    public ngOnDestroy(): void
    {
        this._ngSubscribe$.next();
        this._ngSubscribe$.complete();
    }
}
