<h2 mat-dialog-title class="text-uppercase">{{member.name}}</h2>

<form [formGroup]="form" novalidate autocomplete="off" (submit)="submit($event)">

    <mat-dialog-content>

        <div class="layout-column">

            <mat-form-field appearance="outline" class="flex">
                <mat-label>Member Status</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="0">Pending</mat-option>
                    <mat-option [value]="1">Approved</mat-option>
                </mat-select>
            </mat-form-field>
                
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button type="submit" mat-button class="btn is-primary">
            Save
        </button>

        <button type="button" mat-button class="btn" mat-dialog-close>
            Cancel
        </button>

    </mat-dialog-actions>

</form>
