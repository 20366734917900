
<mat-tab-group dynamicHeight animationDuration="0ms">

    <mat-tab label="Search Users">
        <div class="pt-15">
            <search-user [title]="title" [queryName]="queryName" [excludedIds]="excludedIds" (onSelected)="selectExistingUser($event)"></search-user>
        </div>
    </mat-tab>

    <mat-tab *ngIf="showCreateUser" label="Create User Account">
        <div class="pt-15">
            <add-identity (onSelected)="selectNewUser($event)"></add-identity>
        </div>
    </mat-tab>

</mat-tab-group>
