<h3 mat-dialog-title class="text-uppercase">{{title}}</h3>

<div mat-dialog-content>

    <ng-container *ngIf="!selection" >
        <add-new-identity *ngIf="countryCode !== 'KE'" [countryId] ="countryId" [countryCode]="countryCode" [memberCheckbox]="memberCheckbox"
        (onSelected)="onSelected($event)"></add-new-identity>

        <add-identity  *ngIf="countryCode === 'KE'" (onSelected)="onSelected($event)" [memberCheckbox]="memberCheckbox"></add-identity>
    </ng-container>

    <ng-container *ngIf="selection">

        <dl>
            <dt>Identifaction Number</dt>
            <dd>{{selection.idNumber}}</dd>

            <dt>Name</dt>
            <dd>{{selection.name}}</dd>

            <dt>Date of Birth</dt>
            <dd>{{selection.dateOfBirth | date}}</dd>
        </dl>

        <div class="message is-error" *ngIf="message">{{message}}</div>

        <div class="layout-row layout-align-space-between-start">

            <button type="button" mat-button mat-dialog-close class="btn">
                Cancel
            </button>

            <button type="button" mat-button class="btn is-primary" (click)="submitAsync($event)">
                Confirm
            </button>

        </div>

    </ng-container>

</div>
