import { Injectable } from '@angular/core';

@Injectable()
export class LogService
{
    public log(message: any): void
    {
        console.log(message);
    }

    public info(message: any): void
    {
        console.info(message);
    }

    public error(message: any): void
    {
        console.error(message);
    }
}
