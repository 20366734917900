import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../../abstracts/base.component';
import { CommandService, ICommandResponse } from '../../../providers/command.service';

@Component({
    templateUrl: './edit-member.component.html',
})
export class EditMemberComponent extends BaseComponent
{
    public member: any = null;
    public form: UntypedFormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<EditMemberComponent>,
        private commandService: CommandService
    ) {
        super();

        this.member = data.member;

        this.form = new UntypedFormGroup({
            userId: new UntypedFormControl(this.member.id, [Validators.required]),
            collectiveId: new UntypedFormControl(this.member.collectiveId, [Validators.required]),
            status: new UntypedFormControl(this.member.status, [Validators.required])
        });
    }

    public async submit(ev: Event): Promise<ICommandResponse | void>
    {
        ev.preventDefault();

        if (!this.form.valid) return;
        if (this.member.status === this.form.value.status)
        {
            this.dialogRef.close(0);
            return;
        }

        const data = this.form.value;
        const response = await this.commandService.publishAsync('UserSetCollectiveStatus', data);
        this.dialogRef.close(1);
        return response;
    }
}
