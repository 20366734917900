import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../abstracts/base.component';

@Component({
    selector: 'ddf-loader',
    template: `
            <section class="pv-20 m-8">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <h3 *ngIf="message" style="text-align: center;margin-top: 15px">{{message}}</h3>
            </section>
    `
})
export class LoaderComponent extends BaseComponent
{
    @Input() public message: string | undefined;

    constructor(
    )
    {
        super();
    }
}
