import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '../providers';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface IDistributionChannel {
    id: string;
    name: string;
    countryId: string;
}

export interface TrainerDistributionChannelsModel
{
    id: string;
    name: string;
    organization: string;

}

export interface TrainerDistributionChannelsResult
{
    distributionChannels: TrainerDistributionChannelsModel[]
}

export interface IDistributionChannels {
    distributionChannels: IDistributionChannel[];
    totalCount: number;
}

@Injectable({providedIn: 'any'})
export class DistributionChannelRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public async searchDistributionChannels(): Promise<{distributionChannels: IDistributionChannel[], totalCount: number}>
    {
        const response = await this.queryService.queryAsync<{distributionChannels: IDistributionChannel[], totalCount: number}>("DistributionChannelSearchQuery", {});
        return response.result;
    }

    public async getDistributionChannels(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('DistributionChannelsQuery', data);
        return response.result;
    }

    public async getTrainerDistributionChannels(): Promise<IQueryResponse<TrainerDistributionChannelsResult>>
    {
        return await this.queryService.queryAsync('TrainerDistributionChannelsQuery');
    }

    public create(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelCreate', data);
    }

    public update(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelUpdate', data);
    }

    public member(data:any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetMember', data);
    }

    public removeMember(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveMember', data);
    }

    public payment(data:any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetPayment', data);
    }

    public removePayment(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemovePayment', data);
    }

    public product(data:any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetProduct', data);
    }

    public removeProduct(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveProduct', data);
    }

    public async getDistributionChannel(distributionChannelId: string): Promise<any> {
        const response = await this.queryService.queryAsync("DistributionChannelQuery", { distributionChannelId });
        return response.result;
    }

    public async getPayoutDistributions(search: string = null): Promise<any> {
        const response = await this.queryService.queryAsync("PayoutDistributionsQuery", { SearchString: search });
        return response.result;
    }
}
