import { Injectable } from '@angular/core';
import { MessageCreate } from '@ddfshared/domain/messaging/commands/messageCreate';
import { CommandService, ICommandResponse } from '@ddfshared/providers/command.service';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface MessagesQueryResponse
{
    messages: MessageEntity[];
    totalCount: number;
}

export interface MessageEntity
{
    id: string;
    body: string;
    createdAt: string;
    receiverNames: string;
    senderName: string;
    sentAt: string;
    subject: string;
    totalCount: number
}

export interface MessagesQueryModel
{
    messageIds?: string[];
    skip?: number;
    take?: number;
}

@Injectable({ providedIn: 'any' })
export class MessageRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService
    ) { }

    public getMessages(data: MessagesQueryModel): Promise<IQueryResponse<MessagesQueryResponse>>
    {
        return this.queryService.queryAsync('MessagesQuery', data);
    }

    public getSystemMessages(): Promise<IQueryResponse<MessagesQueryResponse>>
    {
        return this.queryService.queryAsync<MessagesQueryResponse>('SystemMessageQuery');
    }

    public create(data: MessageCreate): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('MessageCreate', data);
    }
}
