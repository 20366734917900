import { Component, Input, OnInit } from '@angular/core';
import { OrderComponent, UserSelect  } from './order.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Dependant } from '@ddfshared/repositories/sale.repository';
import { UserRepository } from '@ddfshared/repositories';
import * as dayjs from 'dayjs';
import { Helper } from '@ddfshared/../app/shared/helper';
import { v4 as uuidv4 } from 'uuid';
import { DateService } from '../../../providers';

@Component({
  selector: 'order-new-dependant',
  templateUrl: 'order-new-dependant.component.html',
  styles: [
  ]
})
export class OrderNewDependantComponent
{
  @Input()
  public parent: OrderComponent;
  public maxDate: Date;
  public minDate: Date;
  public form: UntypedFormGroup;
  public message: string | null = null;
  public skipIprs: boolean = false;
  public ignorePrevious: boolean = false;
  public clickedNext: boolean = false;
  public details: any;
  public dependants: Dependant[] = [];
  private dependant: Dependant | null = null;
  private shortuuid : string = '';
  public countryCode: string = 'KE';

  constructor(private userService: UserRepository,private dateService: DateService, private helper: Helper)
  {
      this.helper.init();
      
      this.maxDate = this.dateService.dayjs.utc().toDate();    

      this.form = new UntypedFormGroup({
        idNumber: new UntypedFormControl(null,[Validators.required]),
        firstName: new UntypedFormControl(null,[Validators.required]),
        familyName : new UntypedFormControl(null,[Validators.required]),
        dateOfBirth: new UntypedFormControl(null,[Validators.required]),
        gender: new UntypedFormControl(0, [Validators.required]),
        phone : new UntypedFormControl(null),
      });
  }

  ngOnInit(): void {

    this.countryCode = this.parent.collective.countryCode;

    if (this.parent.collective.countryCode == "BI" && this.parent.isForJunior){
      this.form.get('idNumber').removeValidators([Validators.required])
      this.shortuuid = uuidv4().substring(0, 8);
  }
  if (this.parent.isForJunior) {
    this.minDate = this.dateService.dayjs.utc().subtract(19, 'year').add(1,'day').toDate();
  }
}


  public async previous(): Promise<void>
  {
      this.parent.previous();
  }

  public async submit(ev: Event): Promise<void>
    {
        ev.preventDefault();

        if (this.form.invalid)
      {
          this.message = this.helper.translate('requiredInputFieldsMustBeCompleted');
          return;
      }

        this.message = null;
        this.clickedNext = true;

        const data = {
          firstName: this.form.value.firstName,
          familyName: this.form.value.familyName,
          gender: Number(this.form.value.gender),
          nationalId: this.form.value.idNumber,
          phoneNumber: this.form.value.phone,
          dateOfBirth: this.form.value.dateOfBirth,
          countryCode: this.parent.collective.countryCode,
          countryId: this.parent.collective.countryId
      }    

      if(this.parent.isForJunior && this.countryCode==='BI'){
        // the userId is split and the first element is appended (`- {a random UUID value}`)
        const generatedIdNumber = `${this.parent.policyholder.id.split('-')[0]}`+ '-' + `${this.shortuuid }`
        data.nationalId = generatedIdNumber;
        
      }
       
      //If Junior create sale without creating user
        if (this.parent.isForJunior)
        {
          var d = dayjs(data.dateOfBirth).utc(true).format();
            this.dependant =
            {
              idNumber: data.nationalId,
              name: data.firstName + ' ' + data.familyName,
              dateOfBirth: new Date(d),
              skipIprs: true,
              isJunior: true,
              isBeneficiary: false,
              skipPhone: true
            }

            this.parent.onBeneficiarySelect(this.parent.policyholder.id);


           this.submitDependants();
        }

        // Spouse

        if (!this.parent.isForJunior)
        {
          const idData = {                
              idNumber: this.form.value.idNumber,          
              firstName: this.form.value.firstName,
              lastName: this.form.value.familyName,
              phoneNumber: this.form.value.phone,
          }

          // check if this user exists in database
          const idCheck = await this.userService.checkBurundiIdentityAsync(idData);

          switch (idCheck.result.status)
        {
            case 1: // exists
            
                const currentPolicyholder: UserSelect = this.parent.policyholder;
                const userSelect: UserSelect =
                {
                    id: idCheck.result.details.id,
                    name: idCheck.result.details.name
                };

                // check spouse details differ from policyholder details
                if (currentPolicyholder.id === userSelect.id) 
                {
                    this.message = this.helper.translate('duplicateDetails', {record: this.helper.translate('spouse')});
                    break;
                }

                // check details does not have an active sale or active policy
                const response = await this.userService.getProfileShort(userSelect.id);
                
                if(!response.isSuccess) 
                {
                    // users registered by different user
                    // a trainer may not have acess to some previously registered members
                    this.message = this.helper.translate('registeredUser');
                    break;
                }
                const profile = response.result;
                if (profile.hasPendingSales || profile.hasActivePolicies)
                {
                    this.message = this.helper.translate('hasActivePoliciesAndSales');
                    break;
                }

                this.dependant =
                {
                    name: idCheck.result.details.name,
                    idNumber: idCheck.result.details.idNumber,
                    userId: idCheck.result.details.id,
                    phone: this.form.value['phone'],
                    dateOfBirth: data.dateOfBirth,
                    isJunior: false,
                    isBeneficiary: false,
                    skipIprs: true,
                    skipPhone: true
                }

                if (this.dependant.userId) this.parent.onBeneficiarySelect(this.dependant.userId);


                this.submitDependants();
                break;
            case 0: // NotFound
              const result = await this.userService.createNewUser(data);

              if (result.isSuccess && result.data.userId)
              {        
                var dob = dayjs(data.dateOfBirth).utc(true).format();
                this.dependant =
                {
                  skipIprs: true,
                  idNumber: data.nationalId,
                  name: data.firstName + ' ' + data.familyName,
                  userId: result.data.userId,
                  phone: data.phoneNumber,
                  dateOfBirth: new Date(dob),
                  isJunior: false,
                  isBeneficiary: false,
                  skipPhone: true
                }
    
                this.parent.onBeneficiarySelect(this.parent.policyholder.id);
    
                this.submitDependants();
    
                }

                this.message = result.message; 
              break;
          
        }

        this.clickedNext = false;   
        }


    }

    private submitDependants(): void
    {
        this.dependants.push(this.dependant);
        this.parent.onDependantsSubmit(this.dependants);
        this.parent.next();
    }

}
