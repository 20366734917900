<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-1">
    <p class="tw-text-2xl tw-font-light tw-text-gray-700">{{title}}</p>
    <p class="tw-text-3xl tw-font-light tw-text-gray-700">{{amount |currency: 'KES '}}</p>

    <form [formGroup]="form" (submit)="submit($event)" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input type="text" matInput formControlName="phoneNumber" />
            <mat-error *ngIf="form.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <button type="submit"
            class="tw-flex-1 tw-p-2 tw-rounded tw-bg-green-500 tw-text-white tw-uppercase hover:tw-bg-green-700 hover:tw-text-gray-50 tw-text-sm"
            [ngClass]="isSent || form.invalid ? 'tw-bg-red-200': 'tw-bg-green-500'" [disabled]="isSent || form.invalid">
            M-PESA Direct
        </button>
    </form>
    <!-- divider -->
    <div class="tw-h-0.5 tw-bg-gray-200 tw-w-full tw-my-5"></div>

    <div class="tw-flex tw-flex-col tw-px-3">
        <p class="tw-text-gray-700 tw-text-sm tw-font-light">Pay using Lipa na M-PESA Pay Bill</p>
        <div class="tw-grid tw-grid-cols-5 tw-mt-3 tw-gap-y-2">

            <!-- Details 0 -->
            <div class="tw-pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" fill="none" viewBox="0 0 24 24"
                    stroke="#2563eb" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="tw-col-span-4 tw-text-sm tw-font-light tw-text-gray-700">
                Select Pay Bill
            </div>

            <!-- Details 1 -->
            <div class="tw-pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" fill="none" viewBox="0 0 24 24"
                    stroke="#2563eb" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="tw-col-span-4 tw-text-sm tw-font-light tw-text-gray-700">
                Enter business no. <span class="tw-font-bold">{{business}}</span>
            </div>

            <!-- Details 2 -->
            <div class="tw-pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" fill="none" viewBox="0 0 24 24"
                    stroke="#2563eb" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="tw-col-span-4 tw-text-sm tw-font-light tw-text-gray-700">
                Enter Account no. <span class="tw-font-bold">{{account}}</span>
            </div>

            <!-- Details 3 -->
            <div class="tw-pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" fill="none" viewBox="0 0 24 24"
                    stroke="#2563eb" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="tw-col-span-4 tw-text-sm tw-font-light tw-text-gray-700">
                Enter Amount <span class="tw-font-bold"> {{amount}}</span>
            </div>


            <!-- Details 4 -->
            <div class="tw-pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5" fill="none" viewBox="0 0 24 24"
                    stroke="#2563eb" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <div class="tw-col-span-4 tw-text-sm tw-font-light tw-text-gray-700">
                Enter PIN and OK
            </div>

        </div>

    </div>
</div>