import { Injectable } from '@angular/core';
import { QueryService, CommandService, ICommandResponse } from '../providers';

@Injectable({providedIn: 'any'})
export class TeamRepository
{
    constructor(
        private commandService: CommandService, 
        private queryService: QueryService,
    ) {}

    public create(data: any): Promise<any>
    {
        return this.commandService.publishAsync('TeamCreate', data);
    }

    public member(data: any): Promise<any>
    {
        return this.commandService.publishAsync('TrainerSetTeam', data);
    }

    public async getTeams(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync("TeamsQuery", data)
        return response.result;
    }

    public async getTeam(teamId: string): Promise<any>
    {
        const response = await this.queryService.queryAsync("TeamQuery", { teamId })
        return response.result;
    }

    public save(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('TeamSave', data);
    }
}
