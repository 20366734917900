import { Component, Inject } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AvailableProduct } from '@ddfshared/repositories/product.repository';


export interface ProductDialogData
{
    products: AvailableProduct[];
    
}

@Component({
    templateUrl: 'product.dialog.component.html',
})
export class ProductDialogComponent
{

    public products: AvailableProduct[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ProductDialogData,
        private dialogRef: MatDialogRef<ProductDialogComponent>
    ) { }

    public ngOnInit(): void
    {
        this.products = this.data.products;

    }

    public async submitAsync($event: string): Promise<void>
    {
        const productId: string = $event;
        this.dialogRef.close(productId);
    }
}
