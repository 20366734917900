import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ConnectionMonitor {
    private connectionMonitor = new BehaviorSubject<boolean>(true);

    constructor() {
        this.connectionMonitor.next(navigator.onLine);
        
        window.ononline = () => {
            this.connectionMonitor.next(true);
        }

        window.onoffline = () => {
            this.connectionMonitor.next(false);
        }
    }

    public monitor(): Observable<boolean>
    {
        return this.connectionMonitor.asObservable();
    }

    public get isOnline(): boolean
    {
        return this.connectionMonitor.getValue();
    }

    public isOnlineFunc(): boolean
    {
        return this.connectionMonitor.getValue();
    }
}
