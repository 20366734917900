import { Component, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserRepository } from '@ddfshared/repositories';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from '@ddfshared/abstracts';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { UserModel } from '@ddfshared/repositories/user.repository';

export interface SearchUserData
{
    title?: string;
    filter: number;
    excludedIds: string[];
    queryName: string;
    showCreateUser: boolean;
}

@Component({
    selector: 'search-user',
    templateUrl: './search-user.component.html'
})
export class SearchUserComponent extends BaseComponent {

    @Input() public title?: string;
    @Input() public excludedIds: string[] = [];
    @Input() public filter: number;
    @Input() public queryName: string;
    @Output() public onSelected = new Subject();

    public searchInput = new UntypedFormControl(null, []);
    public pageEvent: PageEvent = null;
    public result: {users: any[], totalUsers: number} = null;
    public isLoading: boolean = false;

    constructor(
        private userRepository: UserRepository

    ) {
        super();

        this.pageEvent = {
            pageIndex: 0,
            pageSize: 10,
            length: 0,
            previousPageIndex: 0
        };
    }

    public async ngOnInit(): Promise<void> {

        this.searchInput.valueChanges.pipe(
            tap(_ => {
                this.pageEvent.pageIndex = 0;
            }),
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap(_ => this.loadAsync()),
        )
        .subscribe();
    }

    public async loadAsync(pageEvent: PageEvent = null): Promise<void>
    {
        if (pageEvent) {
            this.pageEvent = pageEvent;
        }
        this.isLoading = true;

        const data = {
            skip: this.pageEvent.pageIndex,
            take: this.pageEvent.pageSize,
            searchString: this.searchInput.value,
            excludedIds: this.excludedIds,
            filter: this.filter
        };

        const response = await this.userRepository.searchUsers(data, this.queryName);
        this.result = response.result;
        this.isLoading = false;
    }

    public select(user: UserModel): void
    {
        if (!user) return;
        this.onSelected.next(user);
    }
}
