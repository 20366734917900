import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '../providers';

@Injectable({providedIn: 'any'})
export class MetricRepository {

    constructor(private queryService: QueryService) {}

    public getMetrics(data:any): Promise<IQueryResponse<any>> 
    {
        return this.queryService.queryAsync('MetricsQuery', data);
    }
}
