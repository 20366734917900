import {Injectable} from '@angular/core';
import {ICommandResponse, CommandService} from '../providers';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'any'})
export class CurrencyRepository
{
    constructor(
        private commandService: CommandService) {
    }

    public updateCurrency(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CurrencyUpdate', data);
    }
}
