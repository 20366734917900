import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import
    {
        ConnectionMonitor,
        FileListingService,
        FileUploadService,
        GeoLocationService,
        LogService,
        MetricService,
        UiService,
        ExcelService,
        PwaService
    } from './providers';

import { NotFoundComponent } from './components/not-found';
import { WrapperComponent } from './components/wrapper';
import { MenuComponent } from './components/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientInterceptor } from '@ddfshared/providers/http.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CookieService } from "ngx-cookie-service";
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        MatIconModule,
        MatBadgeModule,
        MatTabsModule,
        MatDividerModule,
        MatButtonModule,
        MatListModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatTooltipModule,
        MatSidenavModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    providers: [
        UiService,
        LogService,
        ConnectionMonitor,
        GeoLocationService,
        FileListingService,
        FileUploadService,
        ExcelService,
        PwaService,
        MetricService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: () =>
            {
                return new HttpClientInterceptor();
            },
            multi: true,
            deps: []
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                role: 'dialog',
                closeOnNavigation: true,
                autoFocus: false,
                hasBackdrop: true,
                width: '40rem',
                maxWidth: '90%',
            } as MatDialogConfig
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 2500,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            } as MatSnackBarConfig
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: <MatDateFormats>{
                ...MAT_NATIVE_DATE_FORMATS,
                display: {
                    ...MAT_NATIVE_DATE_FORMATS.display,
                    dateInput: <Intl.DateTimeFormatOptions>{
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    }
                }
            }
        }
    ],
    declarations: [
        WrapperComponent,
        MenuComponent,
        NotFoundComponent
    ],
    exports: [
        WrapperComponent,
        MenuComponent
    ]
})
export class CoreModule { }
