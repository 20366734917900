import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonComponent } from './component/skeleton.component';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [],
    declarations: [
        SkeletonComponent
    ],
    exports: [
        SkeletonComponent
    ]
})
export class SkeletonModule { }
