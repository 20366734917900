import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DDFSharedConfig, DDF_SHARED_CONFIG } from '../ddfshared.config';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ICommandResponse
{
    commandId: string;
    data?: any;
    executionTime: number;
    isCompleted: boolean;
    isSuccess: boolean;
    message?: string;
}

@Injectable({providedIn: 'any'})
export class CommandService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
        @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig
    ){}

    public async publishAsync(commandName: string, command: {}): Promise<ICommandResponse>
    {
        let response: ICommandResponse | undefined;

        try {
            if (!this.tokenService.isAuth)
            {
                await this.authService.refreshAsync();
            }
    
            const appId: string = this.config.settings.appId.toString();
            const authorization: string = `Bearer ${this.tokenService.token}`;
            const headers = new HttpHeaders({
                Authorization: authorization,
                AppId: appId,
                Command: commandName,
            });
    
            response = await this.httpClient
                .post<ICommandResponse>(`${this.config.settings.commandUrl}`, command, { headers, withCredentials: true })
                .toPromise();
    
        } catch (error) {
            if ('status' in error && error.status === 401)
            {
                this.snackBar.open('Your session has expired. Please sign in again')
                this.router.navigate(['/auth/signin']);
                return;
            }

            this.snackBar.open(error.message || 'An error ocurred')
            throw error;
        }

        return response;
    }
}
