import {NgModule} from '@angular/core';
import {LoaderComponent} from './loader.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    providers: [],
    declarations: [
        LoaderComponent
    ],
    exports: [
        LoaderComponent
    ]
})
export class LoaderModule{}
