import { CommandService } from './../providers/command.service';
import { Injectable } from '@angular/core';
import { ICommandResponse } from '@ddfshared/providers';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface PolicyModel 
{
    id: string;
    policyNumber: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    policyholderPhone: string;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    beneficiaryPhone: string;
    productId: string;
    productName: string;
    validFrom: Date;
    validTo: Date;
    createdAt: Date;
    collectiveId: string;
    collectiveName: string;
    organizationId: string;
    organizationName: string;
    orderId: string;
    orderName: string;
    orderAmount: number;
    properties: string;
    claimMaxDays: number;
    currency: string;
    paidDays: number;
    remainingDays: number;
    dependantId: string;
    dependantName: string;
    dependantNationalId: string;
    dependantPhone: string;
}

export interface ClaimModel
{
    id: string;
    userId: string;
    fullName: string;
    paidAt: Date;
    createdAt: Date;
}

export interface PolicyQueryModel
{
    policy: PolicyModel;
    claims: ClaimModel[];
}

export interface PolicySetBeneficiaryPayload
{
    policyId: string;
    beneficiaryId: string;
    beneficiaryRel?: string;
}


export interface UserPoliciesModel
{
    id: string;
    productName: string;
    createdAt:string;
    validFrom: string;
    validTo: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    policyholderPhone: string;
    policyholderDateOfBirth: string;
    beneficiaryId?: string;
    beneficiaryName?: string;
    beneficiaryNationalId?: string;
    dependantId?: string;
    dependantName?: string;
    dependantNationalId?: string;
    dependantPhone?: string;
    isForJunior: string;
    orderName: string;
    countryId:string;
}


@Injectable({ providedIn: 'any' })
export class PolicyRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService,
    ) { }

    public async getPolicy(policyId: string): Promise<PolicyQueryModel>
    {
        const response = await this.queryService.queryAsync<PolicyQueryModel>('PolicyQuery', { policyId });

        return response.result;
    }

    public async getPolicies(data: any): Promise<any[]>
    {
        const response = await this.queryService.queryAsync<any[]>('PoliciesQuery', data);
        return response.result;
    }

    public updateBeneficiary(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('PolicySetBeneficiary', data);
    }

    public async getUserPoliciesAsync(userId: string): Promise<IQueryResponse<UserPoliciesModel[]>>
    {
        return await this.queryService.queryAsync<UserPoliciesModel[]>('UserPolicies', { userId });
    }
}
