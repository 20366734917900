<div class="tw-flex tw-flex-col tw-items-stretch tw-rounded-md tw-bg-white">
    <div *ngFor="let item of [].constructor(items);">
        <div class="tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
                <div class="tw-rounded-full tw-bg-slate-200 tw-h-10 tw-w-10"></div>
                <div class="tw-flex-1 tw-space-y-6 tw-py-1">
                    <div class="tw-space-y-3">
                        <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                            <div class="tw-h-2 tw-bg-slate-200 tw-rounded tw-col-span-2"></div>
                        </div>
                        <div class="tw-h-2 tw-bg-slate-200 tw-rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>