import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class Helper {
    constructor(private translateService: TranslateService)
    {

    }

    convertToCamelCase(text: string) : string
    {
        const words = text.replace(/[^a-zA-Z0-9]/g, ' ').split(' ');

        const camelCaseWords = words.map((word, index) => {
          if (index === 0) {
            return word.toLowerCase(); // Convert the first word to lowercase
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        });

        const camelCaseText = camelCaseWords.join('');

        return camelCaseText;
    }

    translate(text: string, replace: object = null) : string
    {
        return (replace)
            ? this.translateService.instant(text, replace)
            : this.translateService.instant(text);
    }

    init()
    {
      this.translateService.setDefaultLang('en');
      this.translateService.use(localStorage.getItem('lang') || 'en');
    }
}
