import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ddf-portal-app',
    template: `<div class="layout-fill">
        <router-outlet></router-outlet>
    </div>`
})
export class AppComponent{

    constructor(private translateService: TranslateService) {
        this.translateService.setDefaultLang('en');
        this.translateService.use(localStorage.getItem('lang') || 'en');
    }
}
