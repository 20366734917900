import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

@Pipe({ name: 'defaultDate' })
export class DefaultDatePipe implements PipeTransform
{
    transform(date: Date | string, excludeTime: boolean = false, asUTC: boolean = false): string
    {

        dayjs.extend(utc);

        let format = 'D MMM YYYY';
        if (!excludeTime) format += ', hh:mm A';

        const result = (asUTC)
            ? dayjs.utc(date)
            : dayjs.utc(date).local();

        return result.format(format);
    }
}
