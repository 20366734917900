import { Injectable } from '@angular/core';
import { Underwriter, UnderwriterManagerRequest, UnderwriterResponse, UnderwritersResponse } from '@ddfshared/models/underwriter';
import { CommandService, ICommandResponse, QueryService } from '@ddfshared/providers';

@Injectable({ providedIn: 'any' })
export class UnderwriterRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) {}

    public create(data: Underwriter): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterCreate', data);
    }

    public update(data: Underwriter): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterUpdate', data);
    }

    public addManager(data: UnderwriterManagerRequest): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterSetUser', data);
    }

    public removeManager(data: UnderwriterManagerRequest): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UnderwriterRemoveUser', data);
    }

    public async getUnderwriters(data: any): Promise<UnderwritersResponse>
    {
        const response = await this.queryService.queryAsync<UnderwritersResponse>('UnderwritersQuery', data);
        return response.result;
    }

    public async getUnderwriter(underwriterId: string): Promise<UnderwriterResponse>
    {
        const response = await this.queryService.queryAsync<UnderwriterResponse>('UnderwriterQuery', { underwriterId });
        return response.result;
    }

}
