<form [formGroup]="form" (submit)="submitAsync($event)">

    <div class="layout-column">

        <mat-form-field appearance="outline" class="flex">
            <mat-label>National ID</mat-label>
            <input type="text" matInput formControlName="idNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>Phone Number</mat-label>
            <input type="tel" placeholder="0712345678" matInput formControlName="phone">
            <mat-error>{{ form.controls.phone.errors?.message ? form.controls.phone.errors?.message : 'Required'
                }}</mat-error>
        </mat-form-field>

        <mat-checkbox *ngIf="memberCheckbox" formControlName="addUser">  Add as Collective member </mat-checkbox>
        
        <div class="message is-error mb-15" *ngIf="message">{{message}}</div>

    </div>

    <div class="layout-row layout-align-space-between-center">
        <mat-spinner [ngStyle]="{'visibility':isChecking ? 'visible' : 'hidden'}" [diameter]="24"></mat-spinner>
        <button type="submit" mat-stroked-button [disabled]="form.invalid || isChecking"
            [ngClass]="{'is-primary': form.valid}">
            Next
        </button>
    </div>
</form>