<ng-container *ngIf="isReady; else loading">

    <div class="layout-row layout-align-space-between-start">

        <div class="table-responsive" *ngIf="entries && entries.length > 0; else noEntries">

            <table class="table">
                <tbody>
                    <tr *ngFor="let entry of entries; let i = index;">
                        <td>
                            <strong>{{entry.title}}</strong><br>
                            <p>{{entry.selection}}</p>
                        </td>
                        <td class="text-right">
                            <button type="button" [disabled]="isEditable" (click)="editExistingSaleAsync(i)" mat-icon-button>
                                <mat-icon svgIcon="pencil" aria-label="Edit"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="layout-row layout-align-space-between-start mt-15">

        <button type="button" mat-button class="btn" (click)="cancelEditingSale()">
            Cancel
        </button>

        <div class="text-center" style="color: white;">
            <button type="button" mat-button class="btn is-red"(click)="removeExistingSaleAsync()">
                <mat-icon svgIcon="delete-sweep"></mat-icon>
                Remove
            </button>
        </div>

    </div>
</ng-container>

<ng-template #loading>
    <ddf-loader message="Loading"></ddf-loader>
</ng-template>


<ng-template #noEntries>
    <h4>No entries to edit</h4>
</ng-template>