export class Logger
{
	static get log(): Function
	{
		return window.console && window.console.log && console.log.bind(console);
    }

	static get info(): Function
	{
		return window.console && window.console.info && console.info.bind(console);
	}

    static get trace(): Function
	{
		return window.console && window.console.trace && console.trace.bind(console);
	}

    static get error(): Function
	{
		return window.console && window.console.error && console.error.bind(console);
	}

    static get warn(): Function
	{
		return window.console && window.console.warn && console.warn.bind(console);
	}
}
