<form [formGroup]="form" novalidate (submit)="submitAsync($event)">

    <dl *ngIf="!isRenewable">

        <dt>{{policyholder | translate}}</dt>
        <dd>{{parent.policyholder.name}}</dd>

        <dt>{{product | translate}}</dt>
        <dd>{{parent.product.productName}} ({{parent.product.productCurrency}} {{parent.product.productPrice}})</dd>


        <ng-container *ngIf="parent.dependants.length > 0">

            <table class="table">
                <thead>
                    <tr>
                        <td>{{name | translate}}</td>
                        <td>{{identification | translate}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dependant of parent.dependants">
                        <td>{{dependant.name}}</td>
                        <td *ngIf="countryCode == KE">{{dependant.idNumber}}</td>
                    </tr>
                </tbody>
            </table>

        </ng-container>

    </dl>


    <div class="tw-py-4">
        <mat-checkbox formControlName="confirmed" [checked]="true">{{ 'confirmCorrectInformation' | translate}}</mat-checkbox>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" mat-button class="btn" (click)="previous()">
            {{ 'previous' | translate }}
        </button>

        <button type="submit" mat-button class="btn" [ngClass]="{'tw-bg-brand-default': form.valid}"
            [disabled]="form.invalid || isSubmitted">
            {{ 'confirm' | translate }}
        </button>

    </div>

</form>