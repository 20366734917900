import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '../providers';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface ICompany {
    id: string;
    name: string;
}


export interface ICompanies {
    companies: ICompany[];
    totalCount: number;
}

@Injectable({providedIn: 'any'})
export class CompanyRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public async searchCompanies(): Promise<{companies: ICompany[], totalCount: number}>
    {
        const response = await this.queryService.queryAsync<{companies: ICompany[], totalCount: number}>("CompanySearchQuery", {});
        return response.result;
    }

    public async getCompanies(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync("CompaniesQuery", data);
        return response.result;
    }

    public async members(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync("CompanyMembersQuery", data);
        return response.result;
    }

    public create(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanyCreate', data);
    }

    public update(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanyUpdate', data);
    }

    public user(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CompanySetUser', data);
    }


    public removeUser(data: any): Promise<ICommandResponse>
    {        
        return this.commandService.publishAsync('CompanyRemoveUser', data);
    }

    public async getCompany(companyId: string): Promise<any> {
        const response = await this.queryService.queryAsync("CompanyQuery", { companyId });
        return response.result;
    }
}
