import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BaseComponent } from '@ddfshared/abstracts';
import { AccessService, BreakpointService } from '@ddfshared/providers';
import { MenuLink, UiService } from '@core/providers';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './wrapper.html'
})
export class WrapperComponent extends BaseComponent
{
    public isReady = false;
    public currentBreakpoint: string;
    private _isOpen = false;

    constructor(
        private uiService: UiService,
        private router: Router,
        private accessService: AccessService,
        private breakpointService: BreakpointService
    )
    {
        super();

        this.router.events.pipe(
            filter(x => x instanceof NavigationStart),
            takeUntil(this.ngSubscribe$)
        ).subscribe(() => {
            this.uiService.setLinks([]);
            //this.uiService.closeMenu();
        });

        this.breakpointService.current$.pipe(
            takeUntil(this.ngSubscribe$)
        )
        .subscribe(current => {
            this.currentBreakpoint = `is-${current}`;
        });
    }

    public get sidenavClass(): string[]
    {
        return [ this.currentBreakpoint ];
    }

    public get isOpen(): boolean
    {
        return !this.isMobile || this._isOpen;
    }

    public get mode(): 'over' | 'side'
    {
        return this.isMobile ? 'over' : 'side';
    }

    public get isMobile(): boolean{
        return this.currentBreakpoint === 'is-xs' || this.currentBreakpoint === 'is-sm';
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();
        await this.accessService.loadAccessAsync();
        this.isReady = true;
    }
}
