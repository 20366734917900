import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommandService } from '@ddfshared/providers';
import { BaseComponent } from '../../../abstracts/base.component';
import { SaleSubmitResult } from '@ddfshared/repositories/sale.repository';

export enum ResendPaymentTarget
{
    User = 1,
    Order = 2,
}

export enum PaymentMethod
{
    SendMpesaDirect = 1,
    SendMpesaPaybill = 2,
    SendSystem = 3,
    SendPaymentInfoLater = 10
}

export interface ResendPaymentModel
{
    targetType: ResendPaymentTarget;
    targetId: string;
    targetRef?: string;
    paymentMethod: PaymentMethod;
    phoneNumber: string;
}

export interface SendPaymentDialogModel
{
    title: string;
    amount: number;
    c2bNumber: string;
    phoneNumber: string;
    saleSubmitResult: SaleSubmitResult;
}


@Component({
    templateUrl: './send-payment-dialog.component.html'
})
export class SendPaymentDialogComponent extends BaseComponent
{
    public title: string;
    public message: string;
    public amount: number;
    public business: string;
    public account: string;
    public isSent: boolean = false;
    public form: UntypedFormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: SendPaymentDialogModel,
        private dialogRef: MatDialogRef<SendPaymentDialogComponent>,
        private snackbar: MatSnackBar,
        private commandService: CommandService
    )
    {
        super();
        this.form = new UntypedFormGroup({
            phoneNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\+254[0-9]{7,12}$/)]),
        });

    }

    public getErrorMessage()
    {
        const phoneNumber = this.form.get('phoneNumber');
        if (phoneNumber.hasError('required'))
        {
            return 'You must enter a value';
        }

        return phoneNumber.hasError('pattern') ? 'Not a valid phone number' : '';
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();

        if (!this.data)
        {
            this.snackbar.open('The expected data was not found');
            this.dialogRef.close();
            return;
        }

        this.amount = this.data.amount;
        this.title = this.data.title;
        this.business = this.data.c2bNumber;
        this.account = this.data.saleSubmitResult.orderRef;
        this.form.patchValue({ phoneNumber: this.data.phoneNumber });
    }

    public async submit(ev: Event): Promise<void>
    {
        this.isSent = true;
        ev.preventDefault();

        if (this.form.invalid)
        {
            this.snackbar.open('Form contains error');
            return;
        }

        const data: ResendPaymentModel =
        {
            targetType: ResendPaymentTarget.Order,
            targetId: this.data.saleSubmitResult.orderId,
            paymentMethod: PaymentMethod.SendMpesaDirect,
            phoneNumber: this.form.get('phoneNumber').value
        }

        const response = await this.commandService.publishAsync('ResendPayment', data);
        this.snackbar.open(response.isSuccess ? 'Prompt has been sent' : (response.message || 'Not successful'));
        if (response) this.dialogRef.close(true);
        this.isSent = false;

    }
}
