import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseComponent } from "@ddfshared/abstracts";
import { UserRepository } from "@ddfshared/repositories";

export interface IdentityModel
{
    idNumber: string;
    name: string;
    dateOfBirth: string;
    userId?: string;
    phone?: string;
    skipIprs?: boolean;
    addUser?: boolean;
}

export interface AddIdentityDialogData
{
    title: string;
    countryCode? : string;
    countryId? : string;
    memberCheckbox? : boolean;
}

@Component({
    templateUrl: './add-identity.dialog.html',
})
export class AddIdentityDialog extends BaseComponent
{
    public title: string;
    public selection: any;
    public message: string | undefined;
    public countryCode : string;
    public countryId : string;
    public memberCheckbox : boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: AddIdentityDialogData,
        private dialogRef: MatDialogRef<AddIdentityDialog>,
        private userRepository: UserRepository
    )
    {
        super();
        this.title = this.data.title || 'Add Identity';
        this.countryCode = this.data.countryCode || 'KE';
        this.countryId = this.data.countryId || '';
        this.memberCheckbox = this.data.memberCheckbox;
        
    }

    public onSelected(selection: IdentityModel)
    {
        this.selection = selection;
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();

        this.message = undefined;
        if (!this.selection) return;

        if (this.selection.userId)
        {
            this.dialogRef.close(this.selection);
            return;
        }
    }



}
