import { Component, Inject, Output, Input } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "@ddfshared/abstracts";
import { DDF_SHARED_CONFIG, DDFSharedConfig } from "@ddfshared/ddfshared.config";
import { DateService } from "@ddfshared/providers";
import { UserRepository } from "@ddfshared/repositories";
import { Subject } from "rxjs";
import { IdentityModel } from "./add-identity.dialog";
import * as dayjs from 'dayjs';

@Component({
  selector: 'add-new-identity',
  templateUrl: './add-new-identity.component.html'
})

export class AddNewIdentityComponent extends BaseComponent
{
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public skipIprs: boolean = false;
    public isChecking: boolean = false;

  @Input() countryId : string;
  @Input() countryCode : string;
  @Input() memberCheckbox : boolean;

  @Output() public onSelected = new Subject<IdentityModel>();

  constructor(
      @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig,
      private dateService: DateService,
      private userRepository: UserRepository)
  {
      super();

      this.maxDate = this.dateService.dayjs.utc().toDate();

      this.form = new UntypedFormGroup({
          idNumber: new UntypedFormControl(null,[Validators.required]),
          firstName: new UntypedFormControl(null,[Validators.required]),
          familyName : new UntypedFormControl(null,[Validators.required]),
          dateOfBirth: new UntypedFormControl(null,[Validators.required]),
          gender: new UntypedFormControl(0, [Validators.required]),
          phone : new UntypedFormControl(null),
          haugeCustomerId :  new UntypedFormControl(null,[Validators.required]),
          addUser :  new UntypedFormControl(true)
      });
  }

  public async submitAsync(ev: Event): Promise<void>
  {
      ev.preventDefault();


      if (this.form.invalid)
      {
          this.message = 'Required fields must be submitted';
          return;
      }

      this.message = null;

      const idData = {                
        idNumber: this.form.value.idNumber,          
        firstName: this.form.value.firstName,
        lastName: this.form.value.familyName,
        phoneNumber: this.form.value.phone,
        haugeCustomerId: this.form.value.haugeCustomerId,
    }

    // check if this user exists in database
    const idCheck = await this.userRepository.checkBurundiIdentityAsync(idData)
   

      switch (idCheck.result.status)
        {
            case 1: // exists
            var d = dayjs(idCheck.result.details.dateOfBirth).utc(true).format();
            this.onSelected.next({
                idNumber: idCheck.result.details.idNumber,
                name: idCheck.result.details.name,
                dateOfBirth: d,
                userId: idCheck.result.details.id,
                phone: idCheck.result.details.phoneNumber,
                addUser: this.form.value.addUser
            });
                break;
            case 0: // NotFound

            var c = dayjs(this.form.value.dateOfBirth).utc(true).format();
            const data = {            
                firstName: this.form.value.firstName,
                familyName: this.form.value.familyName,
                gender: Number(this.form.value.gender),
                nationalId: this.form.value.idNumber,
                phoneNumber: this.form.value.phone,
                haugeCustomerId: this.form.value.haugeCustomerId,
                dateOfBirth: c,
                countryCode: this.countryCode,
                countryId: this.countryId
            }

            const response = await this.userRepository.createNewUser(data);

            if (response.isSuccess && response.data.userId)
            {
                this.onSelected.next({
                    idNumber: data.nationalId,
                    name: data.firstName + ' ' + data.familyName,
                    dateOfBirth: data.dateOfBirth,
                    userId: response.data.userId,
                    phone: data.phoneNumber,
                    addUser: this.form.value.addUser
                });
            }

            this.message = response.message;
              break;
          
        }     


  }

}
