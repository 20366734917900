import { Injectable } from '@angular/core';
import { CommandService } from './../providers/command.service';
import { ICommandResponse } from '@ddfshared/providers';
import { IQueryResponse, QueryService } from '../providers/query.service';
import { BehaviorSubject, Observable } from 'rxjs';

export interface SaleSubmitResult
{
    orderId: string;
    orderRef: string;
}

export interface Dependant
{
    idNumber: string;
    relationship?: string;
    phone?: string;
    name?: string;
    dateOfBirth?: Date;
    userId?: string;
    skipIprs: boolean;
    isBeneficiary: boolean;
    isJunior: boolean;
    skipPhone?: boolean;
}

interface SalesRemovalResponseObject
{
    response: string;
    success: boolean;
    kind: "additional"

    

}

interface SalesRemovalResponseNumber
{
    response: string;
    success: boolean;
    kind: "default"
}

export interface SaleSummary
{
    beneficiaryId: string;
    userId: string;
    policyholderId: string;
    policyholderName: string;
    orderType: number;
    productType: string;
    productId: string;
    productName: string;
    productPrice: number;
    serviceFee: number;
    totalProductPrice: number;
    productCurrency: string;
    principalBeneficiary: string;
    properties: {[key: string]: any|null};
    dependants: Dependant[];
}

export interface SaleSubmitModel
{
    collectiveId: string;
    sales: SaleSummary[];
}

export interface SaleOrderResult
{
    id: string;
    orderRef: string;
    amount: number;
    currency: string;
}

@Injectable({ providedIn: 'any' })
export class SaleRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) {}

    public async getSalesAsync(collectiveId: string) : Promise<IQueryResponse<SaleSummary[][]>>
    {
        return this.queryService.queryAsync<SaleSummary[][]>('SaleCacheQuery', { collectiveId });
    }

    public  async getSaleOrderAsync(collectiveId: string): Promise<IQueryResponse<SaleOrderResult>>
    {
        return this.queryService.queryAsync<SaleOrderResult>('SaleOrderQuery', { collectiveId });
    }

    private _sales = new BehaviorSubject<SaleSummary[][]>([]);

    public getSales(): Observable<SaleSummary[][]> 
    {
        return this._sales.asObservable();
    }

    public getSaleSummary(): SaleSummary[]
    {
        return [].concat(...this._sales.value);
    }

    public async loadAsync(collectiveId: string): Promise<void>
    {
        const sales = await this._fetchSalesAsync(collectiveId);
        this._sales.next(sales);
    }

    public async addSaleAsync(collectiveId: string, sale: SaleSummary): Promise<string>
    {
        let sales: SaleSummary[] = this.getSaleSummary();
        if (sales.indexOf(sale) === -1)
        {
            sales.push(sale);
            const response = await this._persistAsync(collectiveId, sales);
            if (response.isSuccess) 
            {
                this._sales.next(await this._fetchSalesAsync(collectiveId));
            }
            return 'Sale added';
        }

        return 'Duplicate sale exists';
    }

    public async updateSaleAsync(collectiveId: string, sale: SaleSummary): Promise<ICommandResponse>
    {
        let sales: SaleSummary[] = this.getSaleSummary();
        const index = sales.findIndex(entry => entry.policyholderId === sale.policyholderId && entry.productType === sale.productType);
        sales[index] = sale;
        const response = await this._persistAsync(collectiveId, sales);
        if (response.isSuccess) 
        {
            this._sales.next(await this._fetchSalesAsync(collectiveId));
        }
        return response;
    }

    public async removeSaleAsync(collectiveId: string, defaultProductId: string, sale: SaleSummary): Promise<SalesRemovalResponseObject | SalesRemovalResponseNumber>
    {
        let sales: SaleSummary[] = this.getSaleSummary();
        const original: number = sales.length;
        const productType = "junior_bundle";
        const juniorProductLimit = 4
        let removedSale  = null ; 

        // own_bundle remove all sales
        if (sale.productId === defaultProductId)
        {
            sales = sales.filter(s => s.policyholderId !== sale.policyholderId);
        }
        else // other bundles
        {
            const additionalProduct = sales.filter(  s => s.policyholderId == sale.policyholderId && s.productId !== defaultProductId && s.productType == productType);
            if (additionalProduct.length > juniorProductLimit && !sale.productName.endsWith("(Additional)"))
            {
                return {response: `Ensure that any ${sale.productType} additional products are removed`, success: false, kind: "additional"};
            }
            // find index to remove

            // check with defaultProductId to prevent matching when id number === birth certificate
            const index: number = sales.findIndex(s => s.productId !== defaultProductId &&
                s.dependants[0].idNumber === sale.dependants[0].idNumber);
            
            if (index >= 0) {

                removedSale  = sales.splice(index, 1);
            }
        }

        await this._persistAsync(collectiveId, sales);
        const response = await this._persistAsync(collectiveId, sales);
        if (response.isSuccess) 
        {
            this._sales.next(await this._fetchSalesAsync(collectiveId));
        }
        return { response: ` ${removedSale[0].productName}`, success: true, kind: "default"}
    }


    public async removeMemberSaleAsync(collectiveId: string, memberId: string): Promise<boolean>
    {
        let sales: SaleSummary[] = this.getSaleSummary();
        sales = sales.filter(s => s.policyholderId !== memberId);
        await this._persistAsync(collectiveId, sales);

        const response = await this._persistAsync(collectiveId, sales);
        if (response.isSuccess) 
        {
            this._sales.next(await this._fetchSalesAsync(collectiveId));
            return true;
        }
        return false;
    }

    public group(sales: SaleSummary[]): [string, SaleSummary][]
    {
        const groups = sales.reduce((sales, sale) =>
        {
            const { policyholderId } = sale;
            sales[policyholderId] = sales[policyholderId] ?? [];
            sales[policyholderId].push(sale);
            return sales;
        }, {});

        return Object.entries(groups);
    }


    public async submitSalesAsync(data: SaleSubmitModel): Promise<ICommandResponse>
    {
        return await this.commandService.publishAsync('SaleSubmit', data);
    }

    public async submitSaleCacheAsync(data: SaleSubmitModel): Promise<ICommandResponse>
    {
        return await this.commandService.publishAsync('SaleCacheSubmit', data);
    }

    // prevent stale cache and support multiple devices have latest version
    private async _fetchSalesAsync(collectiveId: string): Promise<SaleSummary[][]>
    {
        const response = await this.getSalesAsync(collectiveId);
        return response.result;
    }

    private async _persistAsync(collectiveId: string, sales: SaleSummary[]): Promise<ICommandResponse>
    {
        return await this.commandService.publishAsync('SaleCacheSubmit', { collectiveId, sales });
    }

}