import { Component } from '@angular/core';
import { BaseComponent } from '@ddfshared/abstracts';

@Component({
    templateUrl: './not-found.html'
})
export class NotFoundComponent extends BaseComponent
{

}
