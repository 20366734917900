import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface HospitalQueryPayload
{
    searchString?: string;
    countryId?: string;
    parentId?: string;
}

export interface HospitalQueryResult
{
    hospitals: HospitalModel[];
}

export interface HospitalModel
{
    id: string;
    name: string;
    geolocation: string;
}

@Injectable()
export class HospitalRepository
{
    constructor(private queryService: QueryService) { }

    public getHospitals(data: HospitalQueryPayload): Promise<IQueryResponse<HospitalQueryResult>>
    {
        return this.queryService.queryAsync('HospitalQuery', data);
    }
}
