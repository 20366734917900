import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { LoaderModule } from '@ddfshared/modules/loader/loader.module';
import { EmptyListModule } from '@ddfshared/modules/empty-list/empty-list.module';
import { TemplateComponent } from './components/ddf-template';
import { BackButtonModule } from '@ddfshared/modules/back-button/back-button.module';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { ActiveInactivePipe } from '@shared/pipes/active-inactive.pipe';
import { DefaultCurrencyPipe } from '@shared/pipes/default-currency.pipe';
import { DefaultDatePipe } from '@shared/pipes/default-date.pipe';
import { InitialsPipe } from '@shared/pipes/initials.pipe';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { Helper } from './helper';
import { MY_DATE_FORMATS, DayjsDateAdapter } from './dayjs-adapter';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        CdkAccordionModule,
        MatDatepickerModule,
        MatStepperModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSortModule,
        BackButtonModule,
        EmptyListModule,
        TranslateModule.forChild()
    ],
    declarations: [
        TemplateComponent,
        ActiveInactivePipe,
        DefaultCurrencyPipe,
        DefaultDatePipe,
        InitialsPipe,
        SearchPipe,
    ],
    providers: [
        Helper,
        { provide: DateAdapter, useClass: DayjsDateAdapter.bind(this), deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    ],
    exports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        MatDatepickerModule,
        MatStepperModule,
        MatExpansionModule,
        MatMenuModule,
        MatToolbarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatDividerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatIconModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatSliderModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatGridListModule,
        MatDialogModule,
        MatSortModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatChipsModule,
        TemplateComponent,
        LoaderModule,
        EmptyListModule,
        MatAutocompleteModule,
        TranslateModule,
        ActiveInactivePipe,
        DefaultCurrencyPipe,
        DefaultDatePipe,
        InitialsPipe,
        SearchPipe,
    ],
})
export class SharedModule { }
