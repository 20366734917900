import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseComponent } from "@ddfshared/abstracts";

export interface SelectProductDialogData
{
    collectiveId: string;
}

@Component({
    templateUrl: './select-product.dialog.html',
})
export class SelectProductDialogComponent extends BaseComponent
{
    
    public collectiveId: string;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: SelectProductDialogData,
        private dialogRef: MatDialogRef<SelectProductDialogComponent>
    ){
        super();
    }

    public ngOnInit(): void
    {
        this.collectiveId = this.data.collectiveId;
    }

    public async submitAsync($event: string): Promise<void>
    {
        const productId: string = $event;
        this.dialogRef.close(productId);
    }
}
