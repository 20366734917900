import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@ddfshared/providers';
import { IQueryResponse, QueryService } from '../providers/query.service';


export interface ProductModel
{
    id: string;
    createdAt: Date;
    name: string;
    productType: string;
    price: number;
    currency: string;
    underwriter: string;
    level: number;
    description: string;
    canDefault: boolean;
    summary: any;
    properties: ProductPropertiesModel;
    productTags: string[];
    isFamily: boolean;
}

export interface ProductPropertiesModel
{
    isBeneficiaryRequired: boolean;
    maxClaimsAllowed: number;
}

export interface ProductQueryModel
{
    product: ProductModel;
    addons: ProductModel;
}

export interface AvailableProduct
{
    id: string;
    createdAt: Date;
    name: string;
    price: number;
    servicefee: number;
    totalProductPrice: number;
    currency: string;
    productType: string;
    summary: { included: string[]; excluded: string[]; }
    description: string;
    properties: { level: number; };
}

export interface AvailableProductsQueryModel
{
    availableProducts: AvailableProduct[];
    currentProducts: [];
    renewableProducts: [];
    hasPendingOrders: boolean;
    canRenew: boolean;
}

export interface AvailableProducts
{
    userId: string;
    editSale?: boolean;
}

export interface AvailableProductsCollective
{
    collectiveId: string;
}

export interface ProductQuery
{
    productId: string;
}


@Injectable({ providedIn: 'any' })
export class ProductRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) {}

    public getProducts(data: any): Promise<any>
    {
        return this.queryService.queryAsync('ProductsQuery', data);
    }

    

    public create(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ProductCreate', data);
    }

    public update(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ProductUpdate', data);
    }

    public getUnderwriters(data: any): Promise<any>
    {
        return this.queryService.queryAsync<AvailableProductsQueryModel>('UnderwritersQuery', data);
    }

    public getAvailableProducts(data: AvailableProducts): Promise<IQueryResponse<AvailableProductsQueryModel>>
    {
        return this.queryService.queryAsync<AvailableProductsQueryModel>('AvailableProductsQuery', data);
    }

    public getAvailableProductsCollective(data: AvailableProductsCollective): Promise<IQueryResponse<AvailableProductsQueryModel>>
    {
        return this.queryService.queryAsync<AvailableProductsQueryModel>('AvailableProductsQuery', data);
    }

    public getProduct(data: ProductQuery): Promise<IQueryResponse<ProductQueryModel>>
    {
        return this.queryService.queryAsync<ProductQueryModel>('ProductQuery', data);
    }
    public getTrainerProduct(data: any): Promise<any>
    {
        return this.queryService.queryAsync('TrainerProductQuery', data);
    }

}
