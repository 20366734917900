import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '../providers';


export interface CountryModel
{
    id: string;
    code: string;
    name: string;
}

export interface ProvinceModel
{
    id: string;
    name: string;
}

export interface LocationModel
{
    id: string;
    name: string;
}

export interface LocationCountryCodePayload
{
    countryCode: string;
    searchString: string;
}



@Injectable({ providedIn: 'any' })
export class GeolocationRepository
{
    constructor(private queryService: QueryService)
    {
    }

    public async getCountries(countryId?: string): Promise<IQueryResponse<CountryModel[]>>
    {
        return await this.queryService.queryAsync<CountryModel[]>('CountriesQuery', { countryId });
    }

    public async getProvinces(countryId?: string): Promise<IQueryResponse<ProvinceModel[]>>
    {
        return await this.queryService.queryAsync<ProvinceModel[]>('ProvinceQuery', { countryId });
    }

    public getLocations(searchString: string): Promise<IQueryResponse<any>>
    {
        return this.queryService.queryAsync('LocationQuery', { searchString });
    }

    public getLocationsByCountryCode(data: LocationCountryCodePayload): Promise<IQueryResponse<any>>
    {
        return this.queryService.queryAsync('LocationQuery', data);
    }

    public getCounties(): Promise<IQueryResponse<LocationModel[]>>
    {
        return this.queryService.queryAsync('LocationQuery', { countiesOnly: true });
    }
}
