import { Injectable } from '@angular/core';
import { PaginatedSearchQuery } from '@ddfshared/abstracts/query';
import { QueryService, CommandService, IQueryResponse } from '../providers';

export interface CbiTrainerGroups
{
    id: string;
    userId: string;
    type: string;
    createdAt: Date;
    groupName: string;
    groupDuration: string;
    groupMembers: string;
    nationalId: string;
    firstName: string;
    familyName: string;
    name: string;
    dateOfBirth: Date;
    gender: number;
    phone: string;
    email: string;
    location: string;
    status: string;
}

export interface CbiTrainerGroupsQuery 
{
    cbiGroups: CbiTrainerGroups[];
    totalCbiGroups: number;
}



@Injectable({providedIn: 'any'})
export class GroupRepository
{
    constructor(
        private commandService: CommandService, 
        private queryService: QueryService,
    ) {}

    public async getGroups(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync("CbiGroupsQuery", data);
        return response.result;
    }

    /**
     * Get CBI groups assigned to a trainer waiting for approval
     * @param {PaginatedSearchQuery} data pagination and search input
     * @returns {IQueryResponse<CbiTrainerGroupsQuery>} assigned CBI groups
     */
    public async getCbiTrainerGroupsAsync(data: PaginatedSearchQuery): Promise<IQueryResponse<CbiTrainerGroupsQuery>>
    {
        return await this.queryService.queryAsync('CbiTrainerGroupsQuery', data);
    }


    public async getGroup(CbiGroupId: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('CbiGroupQuery', { CbiGroupId });
        return response.result;
    }

    public async action(data: any): Promise<any>
    {
        return this.commandService.publishAsync('CreateCbiGroupAction', data);
    }

    public async assign(data: any): Promise<any>
    {
        return this.commandService.publishAsync('AssignCbiGroupTrainer', data);
    }
}
