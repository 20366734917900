import { ViewportScroller } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BaseComponent } from "@ddfshared/abstracts";
import { AvailableProduct, AvailableProductsCollective, ProductRepository } from "@ddfshared/repositories/product.repository";

@Component({
    selector: 'select-product',
    templateUrl: './select-product.html'
})
export class SelectProductComponent extends BaseComponent
{


    @Input()
    private collectiveId: string;

    @Output()
    public productEvent = new EventEmitter<string>();

    public products: AvailableProduct[];
    public isReady: boolean = false;

    constructor(private productRepository: ProductRepository)
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        const data: AvailableProductsCollective = { collectiveId: this.collectiveId };
        const response = await this.productRepository.getAvailableProductsCollective(data);
        this.products = response.result.availableProducts;
        this.isReady = true;
    }

    public selectProduct(product: AvailableProduct): void
    {
        this.productEvent.emit(product.id);
    }
}
