<h2 mat-dialog-title class="text-uppercase text-center">
    Change Password
</h2>

<form [formGroup]="form" novalidate (submit)="submitAsync($event)">

    <mat-dialog-content>

        <div class="layout-column">

            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="password" />
                <mat-icon matSuffix svgIcon="key"></mat-icon>
                <mat-error>Required. Minimum 4 characters</mat-error>
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirm">
                <mat-icon matSuffix svgIcon="key-link"></mat-icon>
                <mat-error>Must match new password</mat-error>
            </mat-form-field>
            
            <div class="message is-error" *ngIf="message">{{message}}</div>

        </div>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button type="submit" mat-button class="btn is-primary" aria-label="Register" [disabled]="loading">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-icon svgIcon="account-edit"></mat-icon>
            Save
        </button>

        <button type="button" mat-button class="btn" mat-dialog-close>
            Cancel
        </button>

    </mat-dialog-actions>

</form>