
<div class="pv-10 ph-15 tw-flex tw-flex-row tw-justify-between">

    <a routerLink="/" >
        <img src="assets/ddfinance-logo.png" alt="DDFinance" style="max-height: 35px;"/>
    </a>
    <button mat-icon-button (click)="sidenav.toggle()" *ngIf="isMobile " >
        <mat-icon [svgIcon]="isOpen ? 'close' : 'close'" aria-label="Menu"></mat-icon>
    </button>

</div>

<div class="layout-row layout-align-space-between-center p-10">

    <div class="initials layout-row layout-align-center-center">
        <span>{{identity.name | initials }}</span>
    </div>

    <div>
        {{identity.name}}<br>
    </div>

    <div class="flex text-right">
        <button type="button" mat-icon-button (click)="signoutAsync()">
            <mat-icon svgIcon="logout"></mat-icon>
        </button>
    </div>

</div>

<mat-divider></mat-divider>

<mat-nav-list>

    <a mat-list-item [routerLink]="l.link" routerLinkActive="is-active" *ngFor="let l of menuLinks" (click)="selectMenu($event, l.link)" data-test="simple">
        <mat-icon [svgIcon]="l.icon"></mat-icon>
        <span class="pl-10 text-uppercase tw-text-red"  [attr.data-test]="l.title" >{{ l.title | translate }}</span>
    </a>

</mat-nav-list>

<mat-divider></mat-divider>

<mat-nav-list>
    <a mat-list-item *ngFor="let lang of langs" (click)="switchLang(lang)">
        <span class="pl-10 text-uppercase {{ currentLang == lang ? 'text-semibold' : ''}}">{{ lang | translate }}</span>
        <span class="flex-grow"></span> <!-- Add a flex-grow span -->
        <mat-icon *ngIf="currentLang == lang" svgIcon="check"></mat-icon>
    </a>
</mat-nav-list>
