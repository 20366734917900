import { Injectable } from "@angular/core";
import { CommandService, ICommandResponse } from ".";

export interface CbiTrainerGroupsResponse
{
    cbiGroupId: string;
    response: string;
}

@Injectable({ providedIn: 'any' })
export class GroupService
{

    constructor(private _commandService: CommandService) { }


    public async respondCbiGroupTrainer(data: CbiTrainerGroupsResponse): Promise<ICommandResponse> 
    {
        return this._commandService.publishAsync('RespondCbiGroupTrainer', data);
    }
}