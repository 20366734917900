import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '@ddfshared/abstracts/base.component';
import { OrderCreateComponent } from './order-create';

export interface EditExisingEntry
{
    title: string;
    selection: string;
}

@Component({
    selector: 'order-create-edit-existing',
    templateUrl: './edit-existing.html'
})
export class OrderCreateEditExistingComponent extends BaseComponent
{
    @Input()
    public parent: OrderCreateComponent;
    public isReady = false;
    public isEditable = false;
    public entries: EditExisingEntry[] = [];

    constructor(private dialogRef: MatDialogRef<OrderCreateComponent>)
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        await this.loadAsync();
    }

    public async loadAsync(): Promise<void>
    {
        let dependant = this.parent.dependants?.find(dependant => dependant.isBeneficiary);
        
        let beneficiary = dependant ? dependant.name : 'Not assigned';
        if(this.parent.isForJunior) 
        {
            beneficiary = this.parent.policyholder.name;
            this.isEditable = true;
        }

        let beneficiaryEntry: EditExisingEntry = {
            title: 'Beneficiary',
            selection: beneficiary
        }

        this.entries.push(beneficiaryEntry);
        this.isReady = true;
    }


    public cancelEditingSale(): void
    {
        this.dialogRef.close();
    }

    public async editExistingSaleAsync(index: number): Promise<void>
    {
        if (index === 0) // beneficiary details
        {
            this.parent.setStep(3);
        }
    }

    public async removeExistingSaleAsync(): Promise<void>
    {
        this.parent.setRemove(true);
        this.parent.onExistingEdited();
    }

}