import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';

@Injectable()
export class MetricService {

    constructor(private httpClient: HttpClient) {
    }

    public getExchangeRates(): Observable<any> {
        return this.httpClient.get(environment.currencyExchangeUrl);
    }

    public convertCurrency(value, rates, symbol): string {
        if (symbol === 'USD')
            return (value / rates.KES).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            });
        // return KES
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'KES',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    }

}

