import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

@Injectable({providedIn: 'any'})
export class DateService
{
    private _dayjs: dayjs.Dayjs;

    constructor()
    {
        this._dayjs = dayjs.extend(utc);
    }

    public get dayjs(): dayjs.Dayjs
    {
        return this._dayjs;
    }
}
