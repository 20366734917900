import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse, QueryService } from '../providers';

export enum ClaimStatus
{
    UnVerified = -7,
    Verified = -6,
    Inprogress = -5,
    Investigating = -4,
    Rejected = -3,
    Cancelled = -2,
    Disputed = -1,
    Pending = 0,
    Approved = 1,
}

export interface ClaimVerificationFormSubmit
{
    claimId: string;
    verifierId: string;
    verifiedAt : Date;
    verifierStory : string;
    additionalInfo : string;
    levelOfKnowledge : string;
    levelOfTrustworthiness : string;
    recommendation : string;
    verifiedBy : string;
}

@Injectable({ providedIn: 'any' })
export class ClaimRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService
    ) { }

    public async getClaimsAsync(data: any): Promise<{ claims: any[], claimsHighlights: any[], totalCount: number, pendingAmount: any }>
    {
        const response = await this.queryService.queryAsync<any>('ClaimsQuery', data);
        return response.result;
    }

    public async getUnderwritersMetrics(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync<any>('UnderwritersMetricsQuery', data);
        return response.result;
    }
    public async getUnderwriterMetric(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync<any>('UnderwriterMetricQuery', data);
        return response.result;
    }

    public async getClaim(claimId: string): Promise<any>
    {

        const response = await this.queryService.queryAsync<any>('ClaimQuery', { claimId });
        return response.result;
    }

    public async submitNote(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('SubmitClaimNote', data);
    }

    public async setStatus(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimSetStatus', data);
    }

    public async makePayout(claimId: string): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimMakePayout', { claimId });
    }

    public async claimSubmit(PolicyId: string, UserId: string, Data: any)
    {
        return this.commandService.publishAsync('claimSubmit', { PolicyId, UserId, Data })
    }

    public async  claimVerificationSubmit(data: ClaimVerificationFormSubmit): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimVerificationFormSubmit', data)
    }

}