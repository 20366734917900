import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { LoaderModule } from '../loader/loader.module';
import { SearchUserComponent } from './components/search-user.component';
import { SearchUserDialog } from './components/search-user.dialog';
import { PipesModule } from '@ddfshared/pipes/pipes.module';
import { AddIdentityModule } from '@ddfshared/modules/add-identity/add-identity.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatPaginatorModule,
        MatDatepickerModule,
        LoaderModule,
        AddIdentityModule
    ],
    declarations: [
        SearchUserComponent,
        SearchUserDialog
    ],
    exports: [
        SearchUserComponent,
        SearchUserDialog
    ]
})
export class SearchUserModule
{
}
