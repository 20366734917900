import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YY',
},
display: {
    dateInput: 'DD/MM/YY',
    monthYearLabel: 'MMM YY',
    dateA11yLabel: 'DD/MMM/YY',
    monthYearA11yLabel: 'MMMM YY',
  },

};

export const MY_DATE_FORMATS_YEAR: MatDateFormats = {
    parse: {
      dateInput: 'YYYY',
  },
  display: {
      dateInput: 'YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'YYYY',
      monthYearA11yLabel: 'YYYY',
    },
  };

@Injectable()
export class DayjsDateAdapter extends NativeDateAdapter
{
    format(date: Date, displayFormat: any): string
    {
        return dayjs(date).format('DD MMM YYYY');
    }
}


@Injectable()
export class DayjsYearDateAdapter extends NativeDateAdapter
{
    format(date: Date, displayFormat: any): string
    {
        if (displayFormat === 'YYYY')
        {
            return dayjs(date).format(displayFormat);
        }

        return super.format(date, displayFormat);
    }
}

