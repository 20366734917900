import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '../providers/query.service';

export interface IllnessModel
{
    id: string;
    name: string;
}

@Injectable()
export class IllnessRepository
{
    constructor(private queryService: QueryService) { }

    public getIllnesses(): Promise<IQueryResponse<IllnessModel[]>>
    {
        return this.queryService.queryAsync('IllnessQuery', {});
    }
}
