<div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
    <div class="tw-flex-auto tw-uppercase">
        {{subtitle}}
    </div>
    <div>
        <button type="button" mat-icon-button (click)="closeAsync()">
            <mat-icon class="is-error" svgIcon="close"></mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>

    <ng-container *ngIf="currentStep == 1">
        <order-create-edit-existing [parent]="this"></order-create-edit-existing>
    </ng-container>

    <ng-container *ngIf="currentStep == 2">
        <order-create-product [parent]="this"></order-create-product>
    </ng-container>
    <ng-container *ngIf="orderType === 1 && currentStep === 3 && collective.countryCode!=='KE' ">
        <order-new-dependant [parent]="this"></order-new-dependant>        
    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 3 && collective.countryCode==='KE' ">
        <order-create-dependant [parent]="this"></order-create-dependant>        
    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 4">
        <order-create-confirm [parent]="this"></order-create-confirm>
    </ng-container>

    <ng-container *ngIf="orderType !== 1 && currentStep === 3">
        <order-create-confirm [parent]="this"></order-create-confirm>
    </ng-container>

</mat-dialog-content>