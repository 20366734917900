import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpClientInterceptor implements HttpInterceptor
{
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {
        return next.handle(request).pipe(
            catchError((error) => {
                console.log(error.status);
                return throwError(error);
            }));
    }
}