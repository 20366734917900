import { Component, Inject, Output, Input } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "@ddfshared/abstracts";
import { DDF_SHARED_CONFIG, DDFSharedConfig } from "@ddfshared/ddfshared.config";
import { DateService } from "@ddfshared/providers";
import { UserRepository } from "@ddfshared/repositories";
import { CheckIdentityModel } from "@ddfshared/repositories/user.repository";
import { phoneNumberValidator } from "@ddfshared/validators/phoneNumberValidator";
import { Subject } from "rxjs";
import { IdentityModel } from "./add-identity.dialog";

@Component({
    selector: 'add-identity',
    templateUrl: './add-identity.component.html'
})
export class AddIdentityComponent extends BaseComponent
{
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public isChecking: boolean = false;

    @Input() memberCheckbox : boolean;

    @Output() public onSelected = new Subject<IdentityModel>();

    constructor(
        @Inject(DDF_SHARED_CONFIG) private config: DDFSharedConfig,
        private dateService: DateService,
        private userRepository: UserRepository)
    {
        super();

        this.minDate = this.dateService.dayjs.utc().subtract(18, 'year').toDate();
        this.maxDate = this.dateService.dayjs.utc().toDate();

        const pattern = this.config.settings.production
            ? /^[0-9]{4,10}$/
            : /^(\#\!)?[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            phone: new UntypedFormControl(null, [Validators.required, phoneNumberValidator()]),
            addUser :  new UntypedFormControl(true)
        });
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.isChecking = true;

        if (this.form.invalid) return;

        this.message = null;

        const phone = this.formatPhoneNumber(this.form.value['phone']);

        const data: CheckIdentityModel = {
            idType: 1,
            idNumber: this.form.value['idNumber'].toString(),
            phone: phone,
            extendedResult: false,
            includeLocalUsers: true,
            createIfNotFound: true
        };

        const idCheck = await this.userRepository.checkIdentityAsync(data);

        switch (idCheck.status)
        {
            case -6:
                this.message = 'An unknown error occurred. Try again or contact support';
                break;

            case -5: phone
                break;

            case -4:
                this.message = 'User is registered with a different phone number';
                break;

            case -3:
                this.message = 'Phone number is already registered to someone else';
                break;

            case -2:
                this.message = 'Phone number is of wrong format. Phone number must be a valid Safaricom number';
                break;
            case 1: // Found Locally
            case 2: // Found Remotely
                this.onSelected.next({
                    idNumber: idCheck.idNumber,
                    name: idCheck.details.name,
                    dateOfBirth: idCheck.details.dateOfBirth,
                    userId: idCheck.details.userId,
                    phone: phone,
                    addUser: this.form.value.addUser
                });
                break;
            default:
                this.message = 'The identity was not found';
                break;
        }
        this.isChecking = false;
    }

    private formatPhoneNumber(phoneNumber: number): string
    {
        let phone = phoneNumber.toString()
        if (phone.startsWith('254'))
            {
                phone = `+${phone}`;
            }
            else if (phone.startsWith('0'))
            {
                // 07
                phone = phone.slice(1);
                phone = `+254${phone}`;
            }
            else if (phone.startsWith('7'))
            {
                phone = `+254${phone}`;
            }
            else if (phone.startsWith('+254'))
            {
                phone = `${phone}`;
            }

        return phone;
    }
}
