<ng-container *ngIf="isReady; else skeleton">
    
    <ng-container *ngIf="products && products.length > 0; else noProducts">
        <ng-container *ngFor="let product of products">
            <div
                class="tw-m-1 tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-md sm:tw-p-8 dark:tw-bg-gray-800">
                <p class="tw-mb-4 tw-text-xl tw-font-medium tw-text-gray-500 dark:tw-text-gray-400">{{product.name}}</p>
                <div class="tw-flex tw-items-baseline tw-text-gray-900 dark:tw-text-white">
                    <span class="tw-text-2xl tw-font-semibold">{{product.currency}}</span>
                    <span class="tw-text-4xl tw-font-extrabold tw-tracking-tight">{{product.price}}</span>
                    <span class="tw-ml-1 tw-text-xl tw-font-normal tw-text-gray-500 dark:tw-text-gray-400">/year</span>
                </div>
                <!-- List -->
                <ul role="list" class="tw-my-5 tw-space-y-2" *ngFor="let summary of product.summary.included">
                    <li class="tw-flex tw-space-x-3">
                        <!-- Icon -->
                        <svg class="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-blue-600 dark:tw-text-blue-500"
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span
                            class="tw-text-base tw-font-normal tw-leading-tight tw-text-gray-500 dark:tw-text-gray-400">{{summary | titlecase}}</span>
                    </li>
                </ul>
                <ul role="list" class="tw-my-5 tw-space-y-5" *ngFor="let summary of product.summary.excluded">
                    <li class="tw-flex tw-space-x-3 tw-line-through tw-decoration-gray-500">
                        <!-- Icon -->
                        <svg class="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-gray-400 tw-dark:text-gray-500" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="tw-text-base tw-font-normal tw-leading-tight tw-text-gray-500">{{summary | titlecase}}</span>
                    </li>
                </ul>
                <button type="button" (click)="selectProduct(product)"
                    class="tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-200 dark:focus:tw-ring-blue-900 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-inline-flex tw-justify-center tw-w-full tw-text-center">
                    Choose product</button>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #skeleton>
    <skeleton [items]="10"></skeleton>
</ng-template>

<ng-template #noProducts>
    <div class="card tw-h-screen">
        <ddf-empty-list text="This collective does not have a product"></ddf-empty-list>
    </div>
</ng-template>