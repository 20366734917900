import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { ProductDialogComponent } from "./components/product.dialog.component";
import { ProductComponent } from "./components/product.component";
import { EmptyListModule } from "../empty-list/empty-list.module";
import { SkeletonModule } from "../skeleton/skeleton.module";


@NgModule({
    imports: [
        CommonModule,
        EmptyListModule,
        SkeletonModule,
        MatDialogModule
    ],
    providers: [
    ],
    declarations: [
        ProductDialogComponent,
        ProductComponent
    ],
    exports: [
        ProductDialogComponent,
        ProductComponent
    ]
})
export class ProductModule {}