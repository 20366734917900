<form [formGroup]="form" (submit)="submit($event)" >

    <div class="layout-column">

        <mat-form-field appearance="outline" class="flex" *ngIf="!(this.parent.isForJunior && countryCode==='BI')">
            <mat-label>{{parent.isForJunior ? ('birthCertificateNumber' | translate)
                : parent.isForSpouse ? ('spouseNationalId' | translate) : ('nationalId' | translate)}}</mat-label>
            <input type="text" matInput formControlName="idNumber" />           
            <mat-error>{{'required' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput formControlName="firstName"/>
            <mat-error>{{ 'firstName' | translate }} {{ 'isRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex">
            <mat-label>{{ 'lastName' | translate }}</mat-label>
            <input matInput formControlName="familyName"/>
            <mat-error>{{ 'lastName' | translate }} {{ 'isRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'dateOfBirth' | translate }}</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [max]="maxDate" [min]="minDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>{{ 'dateOfBirth' | translate }} {{ 'isRequired' | translate }}</mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
            <mat-label>{{ 'select' | translate }} {{ 'gender' | translate }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="1">{{ 'male' | translate }}</mat-option>
              <mat-option value="2">{{ 'female' | translate }}</mat-option> 
            </mat-select> 
          </mat-form-field> 

        <mat-form-field appearance="outline" class="flex" *ngIf="parent.isForSpouse">
            <mat-label>{{ 'phoneNumber' | translate }}</mat-label>
            <input type="tel" placeholder="" matInput formControlName="phone">
        </mat-form-field>


         <div class="message is-error mb-15" *ngIf="message">{{message}}</div>

    </div>

<div class="tw-flex tw-flex-row tw-justify-between">

   <button type="button" mat-button class="btn" (click)="previous()" [disabled]="ignorePrevious">
    {{ 'previous' | translate }}
    </button>

    <button type="submit" mat-button class="btn" [disabled]="clickedNext">
        {{ 'next' | translate }}
    </button> 
</div>

</form>